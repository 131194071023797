import React, { useEffect, useRef, useState } from 'react';
import { DocumentProps } from 'react-pdf';
import styles from './StoredPDFContent.module.scss';

import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { PdfDocument } from '@/src/components/ExpandedFdoc/ExpandedPDF/PDFDocument';
import useAuthStore from '@/src/hooks/auth';
import { hasMacOSKbd } from '@/src/hooks/interaction';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import { logTrackerIssue, TrackerIssueKind } from '@/src/lib/or';
import { useResourceDocumentDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import clsx from 'clsx';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ReactZoomPanPinchRef, TransformWrapper } from 'react-zoom-pan-pinch';
import { shallow } from 'zustand/shallow';
import Spinner from '../../Spinner/Spinner';
import { useExpandedFdocContentContext } from './../ExpandedFdocContent';
import HeaderControls from './HeaderControls';
import PDFContentProvider from './PDF';
import PDFToolbar from './PDFToolbar';
import ScalableContainer from './ScalableContainer';
import TinyPagesList from './TinyPagesList';

// Small pages on the left that are easy to click to jump on the content
const TINY_PAGE_WIDTH = 54;
const TINY_PAGE_WIDTH_FULL_SCREEN = 87;

const StoredPDFContent: React.FC<{
  isFullScreen: boolean;
  tinyPagesIsVisible: boolean;
}> = ({ isFullScreen, tinyPagesIsVisible }) => {
  const { resource, fullName } = useResourceDocumentDataContextSafe();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { extraZoneRef } = useExpandedFdocContentContext();
  const { pdf, setPdf } = useExpandedFdocContext();

  const contentRef = useRef<HTMLDivElement | null>(null);

  const [contentRect] = useDomContentRect(contentRef.current);

  const [innerRef, setInnerRef] = useState<HTMLDivElement | null>(null);

  const [userScrolled, setUserScrolled] = useState<boolean>(false);
  const user = useAuthStore((state) => state.user, shallow);

  const [documentLoading, setDocumentLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [retryAttempts, setRetryAttempts] = useState(0);

  const fileUrl = resource.fileUrl;

  const onTryAgain = (e?: React.MouseEvent) => {
    e?.preventDefault();
    setDocumentLoading(true);
    setRetryAttempts(0);
    setError(null);
  };

  const onLoadError: DocumentProps['onLoadError'] = (error) => {
    setRetryAttempts((attempts) => attempts + 1);

    if (retryAttempts > 3) {
      setError(error);
      setDocumentLoading(false);
      return;
    }

    logTrackerIssue({
      kind: TrackerIssueKind.PDF_FAILED_LOADING,
      payload: {
        fdocId: resource.id,
        fdocType: resource.kind,
        cdnUrl: fileUrl || null,
        width: contentRect?.width || 0,
        currentPage,
        error: {
          message: error.message,
          stack: error.stack ?? '',
          raw: error,
        },
      },
    });
  };

  const onLoadSuccess: DocumentProps['onLoadSuccess'] = (nextPDF) => {
    setDocumentLoading(false);
    setPdf(nextPDF);
  };

  const [hasSelection, setHasSelection] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  useEffect(() => {
    if (!contentRef.current) return;

    // check for selections in the document, if inside the innerDivRef and it's text we will show toolbar
    const onSelectionChange = (_: Event) => {
      if (!contentRef.current) return;
      const selection = window.getSelection();

      if (!selection) {
        setHasSelection(false);
        return;
      }

      // if it's not inside the innerDivRef, we don't care
      if (!contentRef.current.contains(selection.anchorNode)) {
        setHasSelection(false);
        return;
      }

      // if it's not text, we don't care
      if (selection.type !== 'Range') {
        setHasSelection(false);
        return;
      }

      setHasSelection(true);
    };

    document.addEventListener('selectionchange', onSelectionChange, true);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange, true);
    };
  }, [contentRef]);

  useEffect(() => {
    if (!contentRef.current) return;

    let mouseDown = false;
    let lastElement: HTMLElement | null = null;

    const onMouseDown = (_: MouseEvent) => {
      setIsMouseDown(true);
      mouseDown = true;
    };

    const onMouseUp = (_: MouseEvent) => {
      setIsMouseDown(false);
      mouseDown = false;

      if (lastElement) lastElement.dataset['current'] = 'false';
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!mouseDown) return;

      const element = document.elementFromPoint(e.clientX, e.clientY);
      if (!element) return;

      if (lastElement) lastElement.dataset['current'] = 'false';

      lastElement = element as HTMLElement;
      lastElement.dataset['current'] = 'true';
    };

    window.addEventListener('mousedown', onMouseDown, true);
    window.addEventListener('mouseup', onMouseUp, true);
    window.addEventListener('mousemove', onMouseMove, true);

    return () => {
      window.removeEventListener('mousedown', onMouseDown, true);
      window.removeEventListener('mouseup', onMouseUp, true);
      window.removeEventListener('mousemove', onMouseMove, true);
    };
  }, [contentRef]);

  const [isModDown, setIsModDown] = useState(false);
  const [isPanning, setIsPanning] = useState(false);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if ((hasMacOSKbd() && e.key !== 'Meta') || (!hasMacOSKbd() && e.key !== 'Control')) return;
      setIsModDown(true);
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if ((hasMacOSKbd() && e.key !== 'Meta') || (!hasMacOSKbd() && e.key !== 'Control')) return;
      setIsModDown(false);
    };

    const onBlur = () => {
      setIsModDown(false);
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const [zoomRef, setZoomRef] = useState<ReactZoomPanPinchRef | null>(null);
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    if (!zoomRef) return;

    const wrapperComponent = zoomRef.instance.wrapperComponent;
    const currentDocument = wrapperComponent?.ownerDocument;
    const currentWindow = currentDocument?.defaultView;

    const onWheel = (e: WheelEvent) => {
      // if the target is not a child of the wrapper, we don't care
      if (!wrapperComponent?.contains(e.target as Node)) return;
      setUserScrolled(true);
    };

    currentWindow?.addEventListener('wheel', onWheel, true);

    return () => {
      currentWindow?.removeEventListener('wheel', onWheel, true);
    };
  }, [zoomRef]);

  return (
    <TransformWrapper
      onInit={(ref) => setZoomRef(ref)}
      initialScale={1}
      limitToBounds={true}
      disablePadding={true}
      onZoomStop={(ref) => setScale(ref.state.scale)}
      onTransformed={(_, state) => setScale(state.scale)}
      onPanningStart={() => {
        setIsPanning(true);
      }}
      onPanningStop={() => {
        setUserScrolled(true);
        setIsPanning(false);
      }}
      onWheelStop={() => {
        setUserScrolled(true);
      }}
      onPinchingStop={() => {
        setUserScrolled(true);
      }}
      wheel={{ wheelDisabled: !isModDown }}
      panning={{
        wheelPanning: !isModDown,
        allowLeftClickPan: isModDown,
        allowRightClickPan: isModDown,
        allowMiddleClickPan: isModDown,
      }}
      doubleClick={{ disabled: true }}
      minScale={isFullScreen ? 0.2 : 1}
      maxScale={isFullScreen ? 3 : 1}
    >
      <div
        className={clsx(
          styles.content_pdf,
          (isPanning || isModDown) && styles.pan_mode,
          isPanning && styles.panning,
        )}
        ref={contentRef}
      >
        {error ? (
          <div className={styles.error}>
            <h6>PDF failed to load.</h6>
            <button onClick={onTryAgain}>Click to retry</button>
          </div>
        ) : (
          <PDFContentProvider
            pdf={pdf}
            tinyPagesVisible={tinyPagesIsVisible}
            userScrolled={userScrolled}
            setUserScrolled={setUserScrolled}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            scale={scale}
            contentRef={contentRef}
          >
            {(tinyPagesIsVisible || isFullScreen) && (
              <TinyPagesList
                pageWidth={isFullScreen ? TINY_PAGE_WIDTH_FULL_SCREEN : TINY_PAGE_WIDTH}
                portalTo={isFullScreen ? undefined : extraZoneRef ?? undefined}
                forcedVisible={!isFullScreen}
                className={
                  isFullScreen ? styles.full_screen_tiny_pages : styles.portaled_tiny_pages
                }
              />
            )}

            <div className={styles.pdf__inner} ref={setInnerRef}>
              {documentLoading && (
                <div className={styles.document_loader}>
                  <Spinner size={30} thickness={3} />
                </div>
              )}

              <ScalableContainer>
                <PdfDocument
                  key={`${fileUrl}--${retryAttempts}`}
                  fileUrl={fileUrl}
                  onLoadSuccess={onLoadSuccess}
                  onLoadError={onLoadError}
                  hasSelection={hasSelection}
                  isMouseDown={isMouseDown}
                  tinyPagesIsVisible={tinyPagesIsVisible}
                  isFullScreen={isFullScreen}
                  width={contentRect?.width || 0}
                  wrapperRef={innerRef}
                />
              </ScalableContainer>
            </div>

            {pdf && fileUrl && (
              <HeaderControls
                pdf={pdf}
                isOwner={resource.user?.id === user?.id}
                fileName={fullName}
                storedFileUrl={fileUrl}
              />
            )}
            <PDFToolbar innerDivRef={contentRef.current} />
          </PDFContentProvider>
        )}
      </div>
    </TransformWrapper>
  );
};

export default StoredPDFContent;
