import { useExpandedFdocContentContext } from '@/src/components/ExpandedFdoc/ExpandedFdocContent';
import { useResourceAudioDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { useMediaPlayerControls } from '@/src/modules/resource-detail/components/ExpandedResource/Content/useMediaPlayerControls';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

type ResourceContentVideoProps = {
  isFullscreenEnabled?: boolean;
};

export const ResourceAudioPlayer: React.FC<ResourceContentVideoProps> = () => {
  const { topHeaderControlsRef } = useExpandedFdocContentContext();
  const { resource, handleDownload } = useResourceAudioDataContextSafe();

  const { mediaElementProps, mediaElementRef } = useMediaPlayerControls<HTMLVideoElement>(
    resource.id,
  );

  const downloadButton =
    !!handleDownload && topHeaderControlsRef
      ? createPortal(<ButtonIconDownload onClick={handleDownload} />, topHeaderControlsRef)
      : null;

  return (
    <>
      {downloadButton}

      {!resource.fileUrl ? (
        <Flex justifyContent="center" style={{ padding: '1rem' }}>
          <Spinner opacity={0.1} size={32} />
        </Flex>
      ) : (
        <Audio
          ref={mediaElementRef}
          src={resource.fileUrl}
          controls
          {...mediaElementProps}
          draggable={false}
        />
      )}
    </>
  );
};

const Audio = styled.audio`
  width: 100%;
`;
