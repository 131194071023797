import { create } from 'zustand';

export interface SharedStateStates {
  playingResourceId: string | null;
  mediaTimestamp: number | null;
  pdfPage: number | null;
}

interface SharedStateActions {
  setPlayingResourceId: (resourceId: string | null) => void;
  setMediaTimestamp: (timestamp: number | null) => void;
  setPdfPage: (page: number | null) => void;
}

type SharedStateStore = SharedStateStates & SharedStateActions;

export const useSharedStateStore = create<SharedStateStore>()((set) => ({
  playingResourceId: null,
  mediaTimestamp: null,
  pdfPage: null,
  setPlayingResourceId: (playingResourceId) => set({ playingResourceId }),
  setMediaTimestamp: (timestamp: number | null) => set({ mediaTimestamp: timestamp }),
  setPdfPage: (page: number | null) => set({ pdfPage: page }),
}));
