import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { Fdoc } from '@/src/types/api';
import { useMutation } from '@tanstack/react-query';

type MutationVariables = {
  resource: { id: string; type?: Fdoc['type']; kind?: ResourceDetail['kind'] };
  newTitle: string;
};

enum RenameError {
  TOO_LONG = 'TOO_LONG',
  FAILED_TO_RENAME = 'FAILED_TO_RENAME',
}

const MAX_TITLE_LENGTH = 500;

/**
 * can rename FolderFdoc, NotepadFdoc, StoredFileFdoc
 * guard check removed to simplify the refactor
 * @param resourceId
 * @returns
 */
export const useMutationResourceTitleRename = (resourceId?: string) => {
  const { client } = useWoody();
  const { updateCachedResource } = useQueryCacheResourceHelpers();
  const { track } = useAnalytics();

  const mutation = useMutation({
    mutationKey: ['resource-rename', resourceId],
    mutationFn: async ({ resource, newTitle }: MutationVariables) => {
      if (newTitle.length > MAX_TITLE_LENGTH) {
        throw new Error(RenameError.TOO_LONG);
      }

      return getWoodyResponseData(await client.renameResource(resource.id, newTitle));
    },
    onMutate: ({ resource, newTitle }) => {
      return updateCachedResource({
        id: resource.id,
        name: newTitle,
      });
    },
    onError: (error, _v, context) => {
      const errorMessage = error instanceof Error ? error.message : 'Failed to rename item';

      switch (errorMessage) {
        case RenameError.TOO_LONG: {
          toast({
            id: RenameError.TOO_LONG,
            replace: true,
            content: `Title is too long. Please use ${MAX_TITLE_LENGTH} characters or less.`,
          });
          break;
        }
        default: {
          toast({
            id: RenameError.FAILED_TO_RENAME,
            replace: true,
            content: 'Failed to rename item',
          });
        }
      }

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (_, { resource }) => {
      track(AnalyticsEvents.RenamedResource, {
        resourceId: resource.id,
        type: resource.type || resource.kind,
      });
    },
  });

  return mutation;
};
