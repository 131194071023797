import { useResourceImageDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { PinchZoomImage } from '@/src/modules/ui/components/images/PinchZoomImage';
import { createPortal } from 'react-dom';
import { useExpandedFdocContentContext } from './ExpandedFdocContent';

type MediaContentProps = {
  imageRef: React.RefObject<HTMLImageElement>;
  disableGestures?: boolean;
};
export function MediaContent({ imageRef, disableGestures }: MediaContentProps) {
  const { topHeaderControlsRef } = useExpandedFdocContentContext();
  const { resource, handleDownload } = useResourceImageDataSafe();

  const downloadButton =
    topHeaderControlsRef && !!handleDownload
      ? createPortal(<ButtonIconDownload onClick={handleDownload} />, topHeaderControlsRef)
      : null;

  const imgSrc = resource.thumbnail?.lg || resource.data.webpage?.image.url;

  return imgSrc ? (
    <>
      {downloadButton}
      <PinchZoomImage
        src={imgSrc}
        alt={resource.name || 'Untitled image'}
        ref={imageRef}
        disableGestures={disableGestures}
      />
    </>
  ) : null;
}
