import React, { useMemo } from 'react';

import Spinner from '@/src/components/Spinner/Spinner';
import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import LockIcon from '@/src/icons/LockIcon';
import { pick } from '@/src/lib/store';
import { ResourceList } from '@/src/modules/resources/components/ResourceList/ResourceList';
import { useQueryResourceContextSearch } from '@/src/modules/resources/queries/useQueryResourceContextSearch';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { getSimilarTo } from '@/src/modules/resources/utils/getSimilarTo';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { useQueryUserQuota } from '@/src/modules/user/queries/useQueryUserQuota';
import useUIStore from '@/src/store/ui';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import styles from './SimilarItemsList.module.scss';

const MINIMUM_ITEMS_FOR_SIMILAR_ITEMS = 50;

const SimilarItemsList: React.FC<{
  similarTo: OptimisticDraft<ResourceDetail> | string; // either the fdoc, or the vector for similarity check
  selectedFdocId?: string;
  isFullscreen?: boolean;
}> = ({ similarTo, selectedFdocId = '' }) => {
  const { expandedFdocSidebarTab } = useUIStore(
    (s) => pick(s, ['expandedFdocSidebarTab']),
    shallow,
  );

  const showExpandedFdoc = useShowExpandedFdoc();

  const { data: usage, isLoading: usageLoading } = useQueryUserQuota();
  const total = usage?.items.used ?? 0;

  const isDraft = typeof similarTo !== 'string' && (similarTo.id === 'draft' || similarTo.isDraft);
  const similarToParams = getSimilarTo(similarTo);

  const { resources, fetchNextPage, hasNextPage, isLoading } = useQueryResourceContextSearch(
    {
      ...similarToParams,
      filters: {
        ...similarToParams.filters,
        hasSlug: false,
      },
    },
    {
      enabled: !usageLoading && total > MINIMUM_ITEMS_FOR_SIMILAR_ITEMS && !isDraft,
    },
  );

  const filteredResources = useMemo(() => {
    if (!!selectedFdocId) {
      return resources.filter((item) => item.id !== selectedFdocId);
    }

    return resources;
  }, [resources, selectedFdocId]);

  return isLoading || usageLoading || isDraft ? (
    <div className={styles.spinner_container}>
      <Spinner thickness={3} size={24} />
    </div>
  ) : (
    <div className={styles.similar_items_sidepanel_container}>
      <ScrollArea scrollbarVariant="subtle" style={{ width: '100%' }}>
        {filteredResources.length > 0 && total > MINIMUM_ITEMS_FOR_SIMILAR_ITEMS ? (
          <NewListGrid>
            <ResourceList
              layoutId="similar-items-list"
              onClickResource={(resource) => {
                showExpandedFdoc(resource.id, expandedFdocSidebarTab);
              }}
              resources={filteredResources}
              viewMode="Grid"
              fetchNextPage={hasNextPage ? fetchNextPage : undefined}
            />
          </NewListGrid>
        ) : (
          <div className={styles.no_similar_items}>
            {total < MINIMUM_ITEMS_FOR_SIMILAR_ITEMS ? (
              <>
                <LockIcon size={58} />
                <p>Save more than 50 items to unlock AI suggestions</p>
              </>
            ) : (
              <p>No similar items found.</p>
            )}
          </div>
        )}
      </ScrollArea>
    </div>
  );
};

export default SimilarItemsList;

const NewListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 27px;
  gap: 21px;
  ${mediaMobile} {
    padding: 21px;
  }
`;
