import {
  QueryResourceListContextSearchParams,
  ResourceDetail,
} from '@/src/modules/resources/resources.types';
import { isResourceDetailImage } from '@/src/modules/resources/utils/resourceDetailGuards';

export const getSimilarTo = (
  target: ResourceDetail | string,
): QueryResourceListContextSearchParams => {
  if (typeof target === 'string') {
    return {
      mode: 'semantic-text',
      text: target,
    };
  }

  const isImage = isResourceDetailImage(target);

  if (isImage) {
    return {
      mode: 'similar-image',
      itemId: target.id,
    };
  }

  return {
    mode: 'similar-text',
    itemId: target.id,
  };
};
