import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import { ResourceDetail, ResourceDetailFolder } from '@/src/modules/resources/resources.types';
import { isResourceDetailFolder } from '@/src/modules/resources/utils/resourceDetailGuards';
import { OptimisticDraft } from '@/src/types/draftable';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useResourceClickHandler = (options?: {
  onClickFolder?: (resource: ResourceDetailFolder) => void;
  onClickResource?: (resource: ResourceDetail) => void;
}) => {
  const onClickFolder = options?.onClickFolder;
  const onClickResource = options?.onClickResource;

  const router = useRouter();
  const showExpandedResource = useShowExpandedFdoc();

  const handleClick = useCallback(
    (resource: OptimisticDraft<ResourceDetail>) => {
      if (resource.isDraft) {
        return;
      }

      /**
       * handle folder opening
       */
      if (isResourceDetailFolder(resource)) {
        if (onClickFolder) {
          onClickFolder(resource);
          return;
        } else {
          router.push(`/folders/${resource.id}`);
          return;
        }
      }

      /**
       * handle resource opening
       */

      if (onClickResource) {
        onClickResource(resource);
        return;
      } else {
        showExpandedResource(resource.id);
      }
    },
    [options, showExpandedResource, router],
  );

  return handleClick;
};
