import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { useResponsive } from '@/src/hooks/responsive';
import { useAsyncValue } from '@/src/hooks/useAsyncValue';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import { validateImageUrl } from '@/src/lib/image';
import { MarkdownRender } from '@/src/modules/markdown/components/MarkdownRender';
import { useResourceBookmarkDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { useQueryPageResourceMarkdown } from '@/src/modules/resource-detail/queries/useQueryPageResourceMarkdown';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Image } from '@/src/modules/ui/components/Image';
import Modal from '@/src/modules/ui/components/Modal';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import * as Tabs from '@/src/modules/ui/components/Tabs';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mediaMobile } from '../../ui/styled-utils';
import { pageResourceEmbeds } from '../expandedResource.config';
import { PageResourceMetadata } from './PageResourceMetadata';

const PaddedModalBody = styled(Modal.Body)`
  // Extra bottom padding because of the tab list and tag button.
  padding-bottom: 100px;

  min-height: 100%;
  flex-grow: 1;
`;

const AbsoluteTabsList = styled(Tabs.List)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;

  ${mediaMobile} {
    width: calc(100% - 2rem);
  }
`;

const MarkdownContent = styled(MarkdownRender)`
  font-size: 14px;
  max-width: 490px;
  padding: 16px;
  background: ${cssVar['color-bg-primary']};
  border-radius: 6px;
  color: ${cssVar['color-text-tertiary']};

  /**
   * These added wrapping helps prevent, e.g. enormous links, from causing the entire content to overflow.
   * This is better than 'word-break: break-all' because it will usually prefer whiitespace breaks, and 
   * essentially use word breaks only when necessary.
  */
  overflow-wrap: break-word;
  word-break: break-word;

  // We use display grid here because it was going outside of bounds on mobile.
  display: grid;
  > * {
    // lower block start and end because grid doesn't collapse margins.
    --margin-block-start: 0.5em;
    --margin-block-end: 0.5em;
  }
`;

export const PageResource: React.FC = () => {
  const { isMobileView } = useResponsive();
  const { setToolbarHeight } = useExpandedFdocContext();
  const { resource } = useResourceBookmarkDataSafe();

  const screenshotUrl = useAsyncValue(() =>
    validateImageUrl(resource.data.webpage?.screenshot?.url),
  );
  const [tab, setTab] = useState<string>('metadata');

  const isEmbedded =
    !!resource.originUrl &&
    !!pageResourceEmbeds.find((embed) => embed.matchFn(resource.originUrl!));

  const { data: markdown } = useQueryPageResourceMarkdown(resource, {
    enabled: !isEmbedded,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRect] = useDomContentRect(containerRef.current);
  const tabsListRef = useRef<HTMLDivElement>(null);
  const [tabsListRect] = useDomContentRect(tabsListRef.current);

  const preventArrowKeyNavigation = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault();
    }
  };

  const [imageFailed, setImageFailed] = useState(false);

  const showReaderView = !!markdown;
  const showScreenshotView = !imageFailed && screenshotUrl && !isEmbedded;

  const shouldSetToolbarHeight = Boolean(
    isMobileView ||
      (tabsListRect?.width &&
        containerRect?.width &&
        tabsListRect.width > containerRect.width * 0.75),
  );
  React.useEffect(() => {
    if (shouldSetToolbarHeight) {
      setToolbarHeight(Math.abs((tabsListRect?.height || 0) + 20));
    }
  }, [shouldSetToolbarHeight, setToolbarHeight, tabsListRect?.height]);

  /**
   * Prevent the tab from becoming invalid, since both reader and screenshot views can be hidden.
   * So if the user is on a tab that is not valid we reset to metadata.
   */
  useEffect(() => {
    if (tab === 'reader' && !showReaderView) {
      setTab('metadata');
      return;
    } else if (tab === 'screenshot' && !showScreenshotView) {
      setTab('metadata');
    }
  }, [showReaderView, showScreenshotView, tab]);

  return (
    <Tabs.Root value={tab} onValueChange={setTab} asChild>
      <ScrollArea grow ref={containerRef} innerDisplayFlex>
        <PaddedModalBody background="tertiary">
          {showReaderView && (
            <Tabs.Content value="readerView" asChild>
              <Flex alignItems="center" direction="column" flexGrow justifyContent={'center'}>
                <MarkdownContent
                  data-id="page-resource-markdown"
                  markdown={markdown}
                  parserOptions={{
                    fixMalformedLists: true,
                    fixSpacedStrong: true,
                    includeEmptyLines: false,
                  }}
                />
              </Flex>
            </Tabs.Content>
          )}

          {showScreenshotView && (
            <Tabs.Content value="screenshot" asChild>
              <Flex alignItems="center" direction="column" flexGrow justifyContent="center">
                <Image
                  src={screenshotUrl}
                  alt="Webpage screenshot"
                  errorText="No screenshot available."
                  onError={() => setImageFailed(true)}
                />
              </Flex>
            </Tabs.Content>
          )}

          <Tabs.Content value="metadata" asChild forceMount>
            <Flex alignItems="center" direction="column" flexGrow>
              <PageResourceMetadata />
            </Flex>
          </Tabs.Content>

          {(showReaderView || showScreenshotView) && (
            <AbsoluteTabsList ref={tabsListRef}>
              {showReaderView && (
                <Tabs.Trigger value="readerView" onKeyDown={preventArrowKeyNavigation} tabIndex={0}>
                  Reader
                </Tabs.Trigger>
              )}
              {showScreenshotView && (
                <Tabs.Trigger value="screenshot" onKeyDown={preventArrowKeyNavigation} tabIndex={0}>
                  Screenshot
                </Tabs.Trigger>
              )}
              <Tabs.Trigger value="metadata" onKeyDown={preventArrowKeyNavigation} tabIndex={0}>
                Metadata
              </Tabs.Trigger>
            </AbsoluteTabsList>
          )}
        </PaddedModalBody>
      </ScrollArea>
    </Tabs.Root>
  );
};
