import React from 'react';
import { createPortal } from 'react-dom';

export const PortalPrepend: React.FC<{
  target: Element | undefined | null;
  children: React.ReactNode;
  className?: string;
}> = ({ target, children }) => {
  const [portalContainer] = React.useState(() => {
    const element = document.createElement('div');
    element.dataset.prependPortal = 'true';
    return element;
  });

  React.useEffect(() => {
    if (target) {
      target.prepend(portalContainer);
      return () => {
        target.removeChild(portalContainer);
      };
    }
  }, [target, portalContainer]);

  if (portalContainer) {
    return createPortal(children, portalContainer);
  } else {
    return null;
  }
};
