import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { ResourceDetailBookmark } from '@/src/modules/resources/resources.types';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

/**
 * Fetches and returns the markdown from the text url of a page resource.
 *
 * @param fdoc
 * @param queryOptions
 * @returns
 */
export const useQueryPageResourceMarkdown = (
  resource?: ResourceDetailBookmark,
  queryOptions?: Partial<QueryObserverOptions<string>>,
) => {
  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.pageMarkdown(resource),
    queryFn: async (params) => {
      const safeTextUrl = params.queryKey[1] as string;
      const response = await fetch(safeTextUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch page markdown from ${safeTextUrl}`);
      }

      return response.text();
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    ...queryOptions,
    enabled: isQueryEnabled([!!resource?.data.webpage?.reader?.url, queryOptions?.enabled]),
  });

  return query;
};
