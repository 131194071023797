import {
  codeSyntaxHighlightDarkMode,
  codeSyntaxHighlightLightMode,
} from '@/src/modules/ui/theme/codeSyntaxHighlight';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { darkThemeStylesheet, lightThemeStylesheet } from '@/src/modules/ui/theme/variables';
import { Slot } from '@radix-ui/react-slot';
import React, { ReactNode } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';

interface ForcedThemeProps {
  colorScheme: 'light' | 'dark' | 'system';
  children: ReactNode;
  asChild?: boolean;
  disabled?: boolean;
}

const RedefineThemeVariables = css`
  ${renderCssBasedOnColorScheme({
    light: lightThemeStylesheet,
    dark: darkThemeStylesheet,
  })}
  ${renderCssBasedOnColorScheme({
    light: codeSyntaxHighlightLightMode,
    dark: codeSyntaxHighlightDarkMode,
  })}
`;

const VarRedefined = styled.div`
  ${RedefineThemeVariables}
`;

const SlotRedefined = styled(Slot)`
  ${RedefineThemeVariables}
`;

export const ForcedTheme: React.FC<ForcedThemeProps> = ({
  colorScheme,
  children,
  asChild,
  disabled,
}) => {
  if (disabled) {
    return children;
  }

  return (
    <ThemeProvider theme={{ userColorScheme: colorScheme }}>
      {asChild ? (
        <SlotRedefined>{children}</SlotRedefined>
      ) : (
        <VarRedefined>{children}</VarRedefined>
      )}
    </ThemeProvider>
  );
};
