import { User } from '@/@types/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { MagicRename } from '@/src/modules/magic/components/MagicRename';
import { useAnimatedText } from '@/src/modules/magic/hooks/useAnimatedText';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import Modal from '@/src/modules/ui/components/Modal';
import { cssTextEllipsis, mediaHover, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

/**
 * @TODO more general font variables
 */
const FONT_SIZE = '1rem';

const Input = styled.input`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: ${cssVar['color-text-primary']};
  font-weight: 500;
  ${cssTextEllipsis};
  min-width: 1px;
  position: relative;
  z-index: 10;
`;

const HeaderExtension = styled.div`
  flex-shrink: 0;
  user-select: none;
  padding: 0;
  flex-shrink: 0;
  font-size: ${FONT_SIZE};
  color: ${cssVar['color-text-quaternary']};
  /**
    need to move this closer to the input
    the input can't be shrinked anymore, it would elipsise the text
  */
  transform: translateX(-1px);
`;

const Container = styled.div`
  min-width: 1px;
  display: flex;
  align-items: center;
  padding: 4px 6px 4px 6px;
  border-radius: 6px;
  position: relative;

  ${mediaNotMobile} {
    margin-left: 0.625rem;
  }
  ${mediaHover} {
    &:hover {
      background: ${cssVar['color-bg-tertiary']};
    }
  }
  &:focus-within {
    background: ${cssVar['color-bg-tertiary']};
  }

  /** increase pointer hit area */
  &:before {
    content: '';
    position: absolute;
    inset: -10px;
    z-index: 0;
  }
`;

/**
 * reference element for the input width
 */
const HiddenReferenceWidthTitle = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  min-width: 150px;
  width: max-content;
  font-size: ${FONT_SIZE};
  font-weight: 500;

  // make sure all spaces are preserved so the width is calculated correctly
  white-space: pre;
  border-radius: 6px;
`;

export const ResourceTitleEditable: React.FC<{
  titleInput: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    set: (newName: string) => void;
  };
  user: User | null;
  setIsEditorFocused?: (focused: boolean) => void;
  readOnly?: boolean;
  onSubmit?: (value: string) => void;
}> = ({ titleInput, user, setIsEditorFocused, readOnly = false, onSubmit }) => {
  const titleRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { isMobileView } = useResponsive();

  const { resource } = useResourceDataContextSafe();

  const [isInputFocused, setIsInputFocused] = React.useState(false);

  /**
   * auto resize
   */
  React.useEffect(() => {
    const titleRefEl = titleRef.current;
    const inputRefEl = inputRef.current;
    if (!titleRef || !inputRef || !titleRefEl || !inputRef?.current) {
      return;
    }

    const updateWidth = () => {
      inputRefEl!.style.width = `${titleRefEl.offsetWidth + 1}px`;
    };

    const observer = new MutationObserver(updateWidth);
    const resizeObserver = new ResizeObserver(updateWidth);

    observer.observe(titleRefEl, { attributes: true, childList: true, subtree: true });
    resizeObserver.observe(titleRefEl);

    updateWidth();

    return () => {
      observer.disconnect();
      resizeObserver.disconnect();
    };
  }, [inputRef, titleRef]);

  const refocusTitleInput = React.useCallback(
    (e: React.MouseEvent) => {
      if (isMobileView) {
        setIsInputFocused((state) => !state);
        return;
      }
      // focus and make it so the text cursor is at the end of the title
      const inputRefEl = inputRef?.current;

      if (!inputRefEl || e.target !== e.currentTarget) return;
      inputRefEl.focus();
      const { length } = inputRefEl.value;
      inputRefEl.setSelectionRange(length, length);
    },
    [inputRef, isMobileView],
  );

  const extension = resource.extension;
  const placeholder = `Untitled ${resource.kind === 'notepad' ? 'note' : 'file'}`;

  const { isAnimating, addAnimation, onAnimateText, inputStyle } = useAnimatedText({
    updateRealText: titleInput.set,
    floatingTextStyle: isMobileView
      ? {
          padding: '0.27rem 0.375rem',
          fontWeight: 500,
        }
      : {},
    overflows: isMobileView,
  });

  const [isRenaming, setIsRenaming] = useState(false);

  const sharedInputProps = {
    readOnly: readOnly || isAnimating || isRenaming,
    ['data-or-obscured']: true,
    value: titleInput.value,
    onChange: titleInput.onChange,
    placeholder,
    /**
     * on key down enter - we blur
     * on blur, we trigger submit
     */
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.currentTarget.blur();
      }
    },
    style: {
      ...inputStyle,
      ...(isRenaming
        ? {
            animation: 'soft-blink 2s infinite',
          }
        : {}),
    },
  };

  const magicRenameEnabled =
    !resource.isDraft && !isAnimating && !isResourceStateProcessing(resource.stateProcessing);

  return (
    <>
      {isMobileView && !readOnly && (
        <Modal
          open={isInputFocused || isAnimating || isRenaming}
          onOpenChange={(v) => {
            if (!v) {
              setIsEditorFocused?.(false);
              setIsInputFocused(false);
            }
          }}
        >
          <Modal.Portal>
            <Modal.Overlay visibleOnMobileViewport />

            <ModalContent id="edit-title">
              <VisuallyHidden>
                <DialogTitle>Title</DialogTitle>
                <DialogDescription>Edit the title of the resource</DialogDescription>
              </VisuallyHidden>

              <MagicRename
                resourceId={magicRenameEnabled && isMobileView ? resource.id : undefined}
                onRename={onAnimateText}
                onFetch={() => setIsRenaming(true)}
                onSettled={() => setIsRenaming(false)}
              >
                <TextAreaAutosizeWrapper
                  data-replicated-value={titleInput.value}
                  hideText={isAnimating}
                >
                  {addAnimation(
                    <textarea
                      autoFocus
                      onFocus={(e) => {
                        setIsEditorFocused?.(true);
                        setIsInputFocused(true);
                        e.currentTarget.setSelectionRange(
                          titleInput.value.length,
                          titleInput.value.length,
                        );
                      }}
                      onBlur={() => {
                        onSubmit?.(titleInput.value);
                        setIsEditorFocused?.(false);
                        setIsInputFocused(false);
                      }}
                      {...sharedInputProps}
                    />,
                    !isMobileView,
                  )}
                </TextAreaAutosizeWrapper>
              </MagicRename>
            </ModalContent>
          </Modal.Portal>
        </Modal>
      )}

      <MagicRename
        resourceId={magicRenameEnabled && !isMobileView ? resource.id : undefined}
        onRename={onAnimateText}
        onFetch={() => setIsRenaming(true)}
        onSettled={() => setIsRenaming(false)}
      >
        <Container
          style={{
            cursor: user?.id === resource.user?.id ? 'text' : 'default',
          }}
          onClick={refocusTitleInput}
        >
          <HiddenReferenceWidthTitle ref={titleRef} style={{ minWidth: extension ? '1em' : 120 }}>
            {titleInput.value.length > 0
              ? titleInput.value
              : // default text for the autoresizer
                placeholder}
          </HiddenReferenceWidthTitle>
          {addAnimation(
            <Input
              ref={inputRef}
              type="text"
              onFocus={() => {
                setIsEditorFocused?.(true);
                setIsInputFocused(true);
              }}
              tabIndex={resource.kind === 'notepad' ? 10 : 4}
              onBlur={() => {
                onSubmit?.(titleInput.value);
                setIsEditorFocused?.(false);
              }}
              {...sharedInputProps}
            />,
            isMobileView,
          )}
          {extension && <HeaderExtension onClick={refocusTitleInput}>.{extension}</HeaderExtension>}
        </Container>
      </MagicRename>
    </>
  );
};

const ModalContent = styled(Modal.ContentRaw)`
  position: fixed;
  top: 0;
  inset: 0px;
  z-index: 126;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  padding-top: calc(env(safe-area-inset-top) + 15.5px);
`;

const TextAreaAutosizeWrapper = styled.div<{ hideText?: boolean }>`
  width: 100%;
  display: grid;
  color: ${cssVar['color-text-primary']};
  & > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    opacity: 0.3 !important;
  }
  & > textarea,
  &::after {
    /* Identical styling required!! */
    font: inherit;
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
    padding: 0.25rem 0.375rem;
    overflow-x: hidden;
    font-weight: 500;
    background: ${cssVar['color-bg-tertiary']};
    border: none;
    outline: none;
    color: ${cssVar['color-text-primary']};
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  // after here is the resizer
  &::after {
    content: attr(data-replicated-value) ' ';
  }

  ${({ hideText }) =>
    hideText &&
    css`
      & > textarea,
      &::after {
        color: #00000000;
      }
    `}
`;
