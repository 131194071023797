import { YOUTUBE_URL_REGEX } from '@/src/modules/expandedResource/expandedResource.utils';
import React from 'react';
import { EmbedElementProps } from '../../expandedResource.types';
import { EmbedWrapper } from './EmbedWrapper';

export const YoutubeEmbed: React.FC<EmbedElementProps> = ({ url, children }) => {
  const { videoId, isVertical } = React.useMemo(() => {
    const execResult = new RegExp(YOUTUBE_URL_REGEX).exec(url);

    const videoId = execResult && execResult[execResult.length - 1];

    if (!videoId) {
      return { videoId: null, isVertical: false };
    }

    return { videoId, isVertical: url.includes('/shorts/') };
  }, [url]);

  if (!videoId) {
    return children;
  }

  return (
    <EmbedWrapper orientation={isVertical ? 'vertical' : undefined}>
      <iframe src={`https://www.youtube.com/embed/${videoId}`} allowFullScreen />
    </EmbedWrapper>
  );
};
