import { MediaContent } from '@/src/components/ExpandedFdoc/MediaContent';
import { useResourceDataContext } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ResourceAudioPlayer } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Audio/ResourceAudioPlayer';
import { ResourceVideoPlayer } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Video/ResourceVideoPlayer';
import {
  isResourceDetailAudio,
  isResourceDetailDocument,
  isResourceDetailImage,
  isResourceDetailVideo,
} from '@/src/modules/resources/utils/resourceDetailGuards';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { mediaTablet } from '@/src/modules/ui/styled-utils';
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import FileDisplay from '../FileDisplay/FileDisplay';
import styles from './ExpandedFdoc.module.scss';
import { useExpandedFdocContentContext } from './ExpandedFdocContent';
import StoredPDFContent from './ExpandedPDF/StoredPDFContent';

type StoreFileContentProps = {
  imageRef: React.RefObject<HTMLImageElement>;
  isFullscreenEnabled: boolean;
  tinyPagesIsVisible: boolean;
  disableGestures?: boolean;
};

export function StoredFileContent({
  imageRef,
  isFullscreenEnabled,
  tinyPagesIsVisible,
  disableGestures,
}: StoreFileContentProps) {
  const { topHeaderControlsRef } = useExpandedFdocContentContext();

  const { resource, handleDownload } = useResourceDataContext();

  if (!resource) {
    return null;
  }

  const downloadButton =
    topHeaderControlsRef && handleDownload
      ? createPortal(<ButtonIconDownload onClick={handleDownload} />, topHeaderControlsRef)
      : null;

  if (isResourceDetailDocument(resource)) {
    return (
      <StoredPDFContent
        isFullScreen={isFullscreenEnabled}
        tinyPagesIsVisible={tinyPagesIsVisible}
      />
    );
  }

  if (isResourceDetailImage(resource)) {
    return <MediaContent imageRef={imageRef} disableGestures={disableGestures} />;
  }

  if (isResourceDetailVideo(resource)) {
    return (
      <AudioVideoContainer>
        <ResourceVideoPlayer />
      </AudioVideoContainer>
    );
  }

  if (isResourceDetailAudio(resource)) {
    return (
      <AudioVideoContainer>
        <ResourceAudioPlayer />
      </AudioVideoContainer>
    );
  }

  return (
    <div className={styles.stored_file}>
      {downloadButton}
      <FileDisplay
        fileExtension={resource.extension}
        fileName={resource.name || 'untitled'}
        onClick={handleDownload}
      />
    </div>
  );
}

const AudioVideoContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaTablet} {
    padding: 0;
  }
  width: 100%;
  height: 100%;
  min-height: 100px;
  max-width: 100%;
  min-width: 100px;

  video {
    width: auto;
    height: auto;
    max-height: 100%;

    object-fit: contain;
    border-radius: 6px;
  }
`;
