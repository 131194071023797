import { useExpandedFdocContentContext } from '@/src/components/ExpandedFdoc/ExpandedFdocContent';
import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { useResourceVideoDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { useMediaPlayerControls } from '@/src/modules/resource-detail/components/ExpandedResource/Content/useMediaPlayerControls';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { AnimatePresence, motion } from 'framer-motion';
import React, { CSSProperties, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

type ResourceContentVideoProps = {
  isFullscreenEnabled?: boolean;
};

export const ResourceVideoPlayer: React.FC<ResourceContentVideoProps> = () => {
  const { setToolbarHeight } = useExpandedFdocContext();
  const { topHeaderControlsRef } = useExpandedFdocContentContext();
  const { resource, handleDownload } = useResourceVideoDataContextSafe();

  const { mediaElementProps, mediaElementRef } = useMediaPlayerControls<HTMLVideoElement>(
    resource.id,
  );

  const [videoLoaded, setVideoLoaded] = useState(false);

  const onVideoLoaded = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    setVideoLoaded(true);
    setToolbarHeight(60);

    // we set it to currenTime 0 to force  to show a thumbnail
    // otherwise it will show a blank screen until the video is played
    e.currentTarget.currentTime = 0;
  };

  const downloadButton =
    !!handleDownload && topHeaderControlsRef
      ? createPortal(<ButtonIconDownload onClick={handleDownload} />, topHeaderControlsRef)
      : null;

  const thumbnailAsBackground: CSSProperties =
    (resource.thumbnail && {
      backgroundImage: `url(${resource.thumbnail.md})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }) ||
    {};
  return (
    <>
      {downloadButton}
      {resource.fileUrl && (
        <Video
          preload="metadata"
          onLoadedMetadata={onVideoLoaded}
          src={resource.fileUrl}
          controls
          draggable={false}
          // Media controls
          {...mediaElementProps}
          ref={mediaElementRef}
        />
      )}
      <AnimatePresence>
        {!videoLoaded && (
          <VideoPlaceholder
            key="placeholder"
            style={thumbnailAsBackground}
            initial={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }}
          >
            <Spinner opacity={0.8} size={32} color="#808080" />
          </VideoPlaceholder>
        )}
      </AnimatePresence>
    </>
  );
};

const VideoPlaceholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--fabric-color-bg-primary);
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  border-radius: 0;

  ${renderCssBasedOnColorScheme({
    light: css`
      background: ${cssVar['color-bg-tertiary']};
    `,
    dark: css`
      background: ${cssVar['color-bg-primary']};
    `,
  })}
`;
