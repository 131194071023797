import { useResponsive } from '@/src/hooks/responsive';
import { Position } from '@/src/types/global';
import React from 'react';
import { ResizeableEdges } from '../components/ResizeableEdges';

type MoveableBase = {
  position: Position | null;
  size: { width: number; height: number } | null;
  moved: boolean;
};

type Moveable = MoveableBase & {
  onPositionMouseDown: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
  onResizeMouseDown: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
  minPosition: Position;
  resizeUiHandlers: React.ReactNode;
  updateSize: VoidFunction;
  resetPositionAndSize: VoidFunction;
};

export const MIN_WIDTH = 500;

const MOVEABLE_AREA_EDGE_OFFSET = 10;

export const useResourceMoveAndResize = (
  isFullscreen: boolean,
  sidebarEl: HTMLDivElement | null,
  rootRef: HTMLElement | null,
  contentWrapperEl: HTMLElement | null,
  minHeight: number,
  sidebarOpened: boolean,
  paddingOffsetY: number,
): [Moveable, React.Dispatch<React.SetStateAction<MoveableBase>>] => {
  const { isDesktopView } = useResponsive();

  const [moveable, setMoveable] = React.useState<MoveableBase>({
    position: null,
    size: null,
    moved: false,
  });

  const moved = moveable.moved && isDesktopView && !isFullscreen;

  const minPosition = React.useMemo(() => {
    if (!rootRef) return { x: 0, y: 0 };

    const { left, top } = rootRef.getBoundingClientRect();
    return {
      x: left + MOVEABLE_AREA_EDGE_OFFSET,
      y: top + MOVEABLE_AREA_EDGE_OFFSET,
    };
  }, [rootRef]);

  /**
   * handler when user clicks to move
   */

  const onPositionMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!sidebarEl || !isDesktopView) return;
      const target = e.target;
      const targetWithAttachedListener = e.currentTarget;

      /**
       * of user clicked e.g. child node, do nothing
       */
      if (target !== targetWithAttachedListener) {
        return;
      }

      if (targetWithAttachedListener.getAttribute('data-movable-area') !== 'true') return;

      const onMouseMove = (e: MouseEvent) => {
        // make sure to not let the component go outside the screen or sidebar

        setMoveable((moveable) => {
          const { position, size } = moveable;

          const { innerWidth, innerHeight } = window;
          const { width, height } = size ?? { width: 0, height: 0 };
          const x = position?.x ?? minPosition.x;
          const y = position?.y ?? minPosition.y;

          const newX = x + e.movementX;
          const newY = y + e.movementY;

          // Sidebar on the right side of the screen
          const sidebarRect = sidebarEl.getBoundingClientRect();

          const sidebarLeft = sidebarRect.left ?? innerWidth;

          const constrainX = Math.min(
            Math.max(newX, minPosition.x),
            sidebarLeft - width - MOVEABLE_AREA_EDGE_OFFSET,
          );
          const constrainY = Math.min(
            Math.max(newY, minPosition.y),
            innerHeight - height - MOVEABLE_AREA_EDGE_OFFSET,
          );

          return { ...moveable, position: { x: constrainX, y: constrainY }, moved: true };
        });

        e.preventDefault();
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      // set a first position exactly where the current component already is on the screen
      const rect = contentWrapperEl?.getBoundingClientRect();
      if (rect) {
        setMoveable((moveable) => ({
          ...moveable,
          position: { x: rect.x, y: rect.y },
          size: {
            width: rect.width,
            height: rect.height,
          },
          moved: true,
        }));
      }

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [sidebarEl, isDesktopView, contentWrapperEl, minPosition.x, minPosition.y],
  );

  const onResizeMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const from = e.currentTarget.dataset.from;
      if (!from) return;
      if (!sidebarEl || !isDesktopView) return;

      const onMouseMove = (e: MouseEvent) => {
        const altPressed = e.altKey;

        setMoveable(({ position, size }) => {
          let newWidth = size?.width ?? 0;
          let newHeight = size?.height ?? 0;
          let newX = position?.x ?? minPosition.x;
          let newY = position?.y ?? minPosition.y;

          const sidebarRect = sidebarEl.getBoundingClientRect();
          const rightEdge = sidebarRect.left || window.innerWidth;

          if (from.includes('left')) {
            const potentialWidth = newWidth - e.movementX * (altPressed ? 2 : 1);
            const potentialX = newX + e.movementX;
            if (potentialWidth > MIN_WIDTH && potentialX >= minPosition.x) {
              newWidth = potentialWidth;
              newX = potentialX;
            }
          } else if (from.includes('right')) {
            const potentialWidth = newWidth + e.movementX * (altPressed ? 2 : 1);
            if (potentialWidth > MIN_WIDTH && altPressed) {
              newX = Math.min(Math.max(newX - e.movementX, minPosition.x), rightEdge);
            }

            newWidth = potentialWidth;
          }

          if (from.includes('top')) {
            const potentialHeight = newHeight - e.movementY * (altPressed ? 2 : 1);
            const potentialY = newY + e.movementY;
            if (potentialHeight > minHeight && potentialY >= minPosition.y) {
              newHeight = potentialHeight;
              newY = potentialY;
            }
          } else if (from.includes('bottom')) {
            const potentialHeight = newHeight + e.movementY * (altPressed ? 2 : 1);

            if (potentialHeight > minHeight && altPressed) {
              newY = Math.min(Math.max(newY - e.movementY, minPosition.y), window.innerHeight);
            }

            newHeight = potentialHeight;
          }

          newX = Math.min(Math.max(newX, minPosition.x), rightEdge);
          newY = Math.min(Math.max(newY, minPosition.y), window.innerHeight);

          newWidth = Math.min(
            Math.max(newWidth, MIN_WIDTH),
            rightEdge - newX - MOVEABLE_AREA_EDGE_OFFSET,
          );
          newHeight = Math.min(
            Math.max(newHeight, minHeight),
            window.innerHeight - newY - MOVEABLE_AREA_EDGE_OFFSET,
          );

          return {
            position: { x: newX, y: newY },
            size: { width: newWidth, height: newHeight },
            moved: true,
          };
        });
        e.preventDefault();
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      // set a first position exactly where the current component already is on the screen
      const rect = contentWrapperEl?.getBoundingClientRect();
      if (rect)
        setMoveable((moveable) => ({
          ...moveable,
          position: { x: rect.x, y: rect.y },
          moved: true,
        }));

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      e.preventDefault();
    },
    [
      contentWrapperEl,
      isDesktopView,
      minHeight,
      minPosition.x,
      minPosition.y,
      sidebarEl,
      setMoveable,
    ],
  );

  const resizeUiHandlers = React.useMemo(() => {
    if (isDesktopView && !isFullscreen) {
      return <ResizeableEdges onResizeMouseDown={onResizeMouseDown} />;
    }
  }, [isDesktopView, isFullscreen, onResizeMouseDown]);

  const updateSize = React.useCallback(() => {
    setMoveable((moveable) => {
      if (!moveable.position || !sidebarEl) return moveable;

      const { position, size } = moveable;
      // need to make sure the component won't fall off the screen

      let newX = position.x;
      let newY = position.y;
      let newWidth = Math.max(MIN_WIDTH, size?.width ?? 0);
      let newHeight = Math.max(minHeight, size?.height ?? 0);

      const sidebarRect = sidebarEl.getBoundingClientRect();
      const sidebarLeft = sidebarRect.left ?? window.innerWidth;

      newX = Math.max(Math.min(newX, sidebarLeft - newWidth), 0);
      newY = Math.max(Math.min(newY, window.innerHeight - newHeight), 0);

      if (newX + newWidth >= sidebarLeft) {
        newWidth = sidebarLeft - newX;
      }

      if (newY + newHeight >= window.innerHeight) {
        newHeight = window.innerHeight - newY;
      }

      return {
        ...moveable,
        position: { x: newX, y: newY },
        size: { width: newWidth, height: newHeight },
      };
    });
  }, [minHeight, sidebarEl]);

  const resetPositionAndSize = React.useCallback(() => {
    setMoveable((moveable) => {
      if (!moveable.position) return moveable;

      return {
        ...moveable,
        position: null,
        size: null,
        moved: false,
      };
    });
  }, [setMoveable]);

  /**
   * update size when window resized
   */

  // This useEffect will listen for resize events and update the moveable state accordingly
  // It will also update the moveable state when the fdoc changes
  React.useEffect(() => {
    if (!contentWrapperEl || !sidebarEl || !isDesktopView) return;

    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [contentWrapperEl, isDesktopView, sidebarEl, updateSize]);

  // on wrapper resize
  React.useEffect(() => {
    if (!contentWrapperEl || moveable.moved) return;

    const updateSize = () => {
      const rect = contentWrapperEl.getBoundingClientRect();
      setMoveable((moveable) => ({
        ...moveable,
        size: { width: rect.width, height: rect.height - paddingOffsetY },
        position: { x: rect.x, y: rect.y },
      }));
    };

    updateSize();

    const observer = new ResizeObserver(updateSize);
    observer.observe(contentWrapperEl);

    // also a mutation observer to update the position when the contentWrapper moves
    const observer2 = new MutationObserver(updateSize);
    observer2.observe(contentWrapperEl, { attributes: true, attributeFilter: ['style'] });

    // listen to scroll and resize events on the page
    window.addEventListener('scroll', updateSize);
    window.addEventListener('resize', updateSize);

    return () => {
      observer.disconnect();
      observer2.disconnect();
      window.removeEventListener('scroll', updateSize);
      window.removeEventListener('resize', updateSize);
    };
  }, [contentWrapperEl, moveable.moved, paddingOffsetY]);
  /**
   * resize when sidebar visibility changes
   */

  React.useEffect(() => {
    updateSize();
  }, [sidebarOpened, updateSize]);

  return React.useMemo(
    () => [
      {
        ...moveable,
        onPositionMouseDown,
        onResizeMouseDown,
        moved,
        minPosition,
        resizeUiHandlers,
        updateSize,
        resetPositionAndSize,
      },
      setMoveable,
    ],
    [
      moveable,
      onPositionMouseDown,
      onResizeMouseDown,
      moved,
      minPosition,
      resizeUiHandlers,
      updateSize,
      resetPositionAndSize,
    ],
  );
};
