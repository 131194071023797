import MessageForm from '@/src/components/Chatbot/MessageForm';
import MessagesList, { MessagesListAnon } from '@/src/components/Chatbot/MessageList';
import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { MessagesProvider } from '@/src/hooks/chatbot';
import * as AssistantHeader from '@/src/modules/assistant/components/AssistantHeader';
import { AssistantQuickActions } from '@/src/modules/assistant/components/AssistantQuickActions';
import { AnonBottomBarSignup } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/AnonBottomBarSignup';
import { useSidebarScrollbarFadeColor } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Sidebar';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P } from '@/src/modules/ui/components/Typography';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { AnimatePresence } from 'framer-motion';

const ExpandedFdocSidebarAssistant: React.FC<{
  isFullscreen: boolean;
  resourceId: string;
  canAutoFocus?: boolean;
}> = ({ isFullscreen, resourceId, canAutoFocus }) => {
  const fadeColor = useSidebarScrollbarFadeColor();
  const { isAnon } = useExpandedFdocContext();

  return (
    <MessagesProvider chatAssistantOpen={true} expandedFdocId={resourceId}>
      <ForcedTheme colorScheme="dark" asChild disabled={isFullscreen}>
        <Flex
          direction="column"
          style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
        >
          <AssistantHeader.Root>
            <AssistantHeader.Left>
              <P size="xs">Asking about:</P>
              <AssistantHeader.ContextSelector />
            </AssistantHeader.Left>
            <AssistantHeader.ResetButton />
          </AssistantHeader.Root>
          {isAnon ? (
            <MessagesListAnon />
          ) : (
            <>
              <MessagesList fadeColor={fadeColor} />
              <AssistantQuickActions />
            </>
          )}
        </Flex>
      </ForcedTheme>
      <AnimatePresence initial={false}>
        {isAnon ? (
          <AnonBottomBarSignup>
            <p>Assistant is available to logged-in users.</p>
          </AnonBottomBarSignup>
        ) : (
          <MessageForm autoFocus={canAutoFocus} />
        )}
      </AnimatePresence>
    </MessagesProvider>
  );
};

export default ExpandedFdocSidebarAssistant;
