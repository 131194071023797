import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { AttributeMap } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import {
  attributeDateFormatter,
  isValidAttribute,
} from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/utils';
import { useQueryResourceAncestors } from '@/src/modules/resource-detail/queries/useQueryResourceAncestors';
import { detailsToAttributeMaps } from '@/src/modules/resource-detail/resource-details.config';
import { LocationSelectorNodePathItem } from '@/src/modules/resources/components/DestinationSelector/types';
import { ancestorToLocationSelectorNodePathItem } from '@/src/modules/resources/components/DestinationSelector/utils';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import Divider from '@/src/modules/ui/components/Divider';
import { Flex } from '@/src/modules/ui/components/Flex';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { DL, DLItem, DT, DD as FDD } from '@/src/modules/ui/components/Typography';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { generateKind } from '@/src/utils/files';
import { formatDiskSpace, parseIntOrString } from '@/src/utils/number';
import { capitalizeFirstLetter } from '@/src/utils/text';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const DD = styled(FDD)`
  user-select: text;
`;

const Container = styled(Flex).attrs((p) => {
  return {
    direction: 'column',
    gap: 'elementsContainer',
    ...p,
  };
})`
  padding: 10px 12px;
  font-size: 0.9rem;

  ${mediaMobile} {
    padding: 14px;
  }
`;

const StyledScrollArea = styled(ScrollArea)`
  display: grid;

  [data-radix-scroll-area-viewport] > div {
    display: grid;
  }
`;

/**
 * @deprecated using this only as a fallback for now as the status change is a breaking change
 * once the BE is released, this shouldn't be necesssary anymore
 * https://linear.app/futurebrowser/issue/FUT-5215/remove-resourcedataattributesstatus-fallback-once-both-fe-be-are
 *
 */
const getAttributesStatus = (details: ResourceDetail) => {
  if ('jobsProcessing' in details && details.jobsProcessing.attributes) {
    return details.jobsProcessing.attributes;
  }

  if (
    details.data &&
    'attributes' in details.data &&
    details.data.attributes &&
    'status' in (details as any).data.attributes
  ) {
    return (details as any).data.attributes.status;
  }

  return null;
};

const isAttributesProcessing = (details: ResourceDetail) => {
  const processingStatus = getAttributesStatus(details);

  if (!processingStatus || ['processing', 'pending'].includes(getAttributesStatus(details))) {
    return true;
  }
  return false;
};

const ExtraPanelInfo: React.FC = () => {
  const { resource } = useResourceDataContextSafe();

  const { data: ancestors } = useQueryResourceAncestors(resource?.id);

  const resourcePath = useMemo<LocationSelectorNodePathItem[]>(
    () => ancestors?.map(ancestorToLocationSelectorNodePathItem) ?? [],
    [ancestors],
  );

  const attributes: AttributeMap[] = detailsToAttributeMaps(resource).filter(
    isValidAttribute,
  ) as AttributeMap[];

  const size = 'size' in resource ? parseIntOrString(resource.size ?? 0) : 0;
  const extension = resource.extension || '';
  const mimeType = resource.mimeType;
  const createdAt = resource.createdAt;
  const modifiedAt = resource.modifiedAt;

  const isProcessing = isAttributesProcessing(resource);

  return (
    <ForcedTheme colorScheme="dark" asChild>
      <StyledScrollArea>
        <Container>
          <DL>
            <DLItem>
              <DT>Path</DT>
              <DD>{resourcePath.map((p) => p.title).join(' / ')}</DD>
            </DLItem>

            <DLItem>
              <DT>Size</DT>
              <DD>
                {parseIntOrString(size).toLocaleString('en-US', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  useGrouping: true,
                })}{' '}
                bytes ({formatDiskSpace(size)} on disk){' '}
              </DD>
            </DLItem>

            <DLItem>
              <DT>Kind</DT>
              <DD>
                {mimeType
                  ? generateKind(mimeType, extension)
                  : capitalizeFirstLetter(resource.kind)}{' '}
              </DD>
            </DLItem>

            {createdAt && (
              <DLItem>
                <DT>Created</DT>
                <DD>{attributeDateFormatter(createdAt)}</DD>
              </DLItem>
            )}

            {modifiedAt && (
              <DLItem>
                <DT>Modified</DT>
                <DD>{attributeDateFormatter(modifiedAt)}</DD>
              </DLItem>
            )}
          </DL>
          {isProcessing && (
            <>
              <Divider />
              <Flex justifyContent="center" alignItems="center" style={{ padding: '1rem' }}>
                <Spinner size={24} />
              </Flex>
            </>
          )}
          {attributes.length > 0 && (
            <>
              <Divider />
              <DL>
                {attributes.map(({ label, value }) => (
                  <DLItem key={label}>
                    <DT>{label}</DT>
                    <DD>{value}</DD>
                  </DLItem>
                ))}
              </DL>
            </>
          )}
        </Container>
      </StyledScrollArea>
    </ForcedTheme>
  );
};

export { ExtraPanelInfo };
