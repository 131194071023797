import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import SimilarItemsList from '@/src/components/ExpandedFdoc/SimilarItemsList';
import Truncator from '@/src/components/Truncator/Truncator';
import CloseIcon from '@/src/icons/CloseIcon';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { AnonBottomBarSignup } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/AnonBottomBarSignup';
import React from 'react';
import styles from './ExpandedFdoc.module.scss';

export const ExpandedFdocSidebar_SimilarContent: React.FC<{
  hideSimilar: boolean;
  isFullscreen: boolean;
}> = ({ hideSimilar, isFullscreen }) => {
  const [similarTextRef, setSimilarTextRef] = React.useState<HTMLDivElement | null>(null);

  const { resource } = useResourceDataContextSafe();

  const { similarText, setSimilarText, isAnon } = useExpandedFdocContext();
  if (hideSimilar) {
    return;
  }

  if (isAnon) {
    return (
      <>
        <div
          className={styles.item_preview__sidebar__comments__empty}
          style={{ paddingTop: 20, flexGrow: 1 }}
        >
          Nothing to show here.
        </div>
        <AnonBottomBarSignup>
          <p>Similar items are only available to logged-in users.</p>
        </AnonBottomBarSignup>
      </>
    );
  }

  return (
    <>
      {similarText && (
        <div className={styles.item_preview__sidebar__similar__badge} ref={setSimilarTextRef}>
          <span>Similar to: “</span>
          <span className={styles.item_preview__sidebar__similar__badge__text}>
            <Truncator
              text={similarText}
              maxRows={2}
              parentRef={similarTextRef}
              offsetBefore={63}
            />
          </span>
          <span>”</span>
          <button
            tabIndex={7}
            className={styles.item_preview__sidebar__similar__badge__button}
            onClick={() => setSimilarText?.(undefined)}
          >
            <CloseIcon />
          </button>
        </div>
      )}

      <SimilarItemsList
        similarTo={similarText ?? resource}
        selectedFdocId={resource.id}
        isFullscreen={isFullscreen}
      />
    </>
  );
};
