import { useUnmount } from '@/src/hooks/useUnmount';
import { useSharedStateStore } from '@/src/store/sharedState';
import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

export const useMediaPlayerControls = <T extends HTMLVideoElement | HTMLAudioElement>(
  resourceId: string,
) => {
  const mediaElementRef = React.useRef<T | null>(null);
  const [localMediaPlaying, setLocalMediaPlaying] = useState(false);

  const { playingResourceId, mediaTimestamp, setPlayingResourceId, setMediaTimestamp } =
    useSharedStateStore(
      (state) => ({
        playingResourceId: state.playingResourceId,
        setPlayingResourceId: state.setPlayingResourceId,
        mediaTimestamp: state.mediaTimestamp,
        setMediaTimestamp: state.setMediaTimestamp,
      }),
      shallow,
    );

  const onPlay = (e: React.SyntheticEvent<T>) => {
    if (e.currentTarget !== mediaElementRef.current) return;

    // played previous video so reseting to 0
    if (playingResourceId !== resourceId) {
      e.currentTarget.currentTime = 0;
    }
    setPlayingResourceId(resourceId);
    setLocalMediaPlaying(true);
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  const onPause = (e: React.SyntheticEvent<T>) => {
    if (e.currentTarget !== mediaElementRef.current) return;
    setPlayingResourceId(null);
    setLocalMediaPlaying(false);
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  const onSeeked = (e: React.SyntheticEvent<T>) => {
    if (e.currentTarget !== mediaElementRef.current) return;
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  const onTimeUpdate = (e: React.SyntheticEvent<T>) => {
    if (e.currentTarget !== mediaElementRef.current) return;
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  useUnmount(() => {
    setPlayingResourceId(null);
    setLocalMediaPlaying(false);
    setMediaTimestamp(null);
  });

  const mediaEl = mediaElementRef.current;

  /**
   * stop media when the playingResourceId changes
   */
  useEffect(() => {
    if (
      playingResourceId !== resourceId &&
      mediaElementRef.current &&
      !mediaElementRef.current.paused
    ) {
      mediaElementRef.current?.pause();
    }
  }, [playingResourceId, resourceId]);

  /**
   * sync media timestamp with the store's timestamp if the media is playing
   * (multiplayer sync feature)
   */
  useEffect(() => {
    if (!mediaTimestamp || playingResourceId !== resourceId) return;

    if (mediaEl && Math.abs(mediaEl.currentTime - mediaTimestamp) > 0.2) {
      mediaEl.currentTime = mediaTimestamp;
    }
  }, [mediaTimestamp, mediaEl, playingResourceId, resourceId]);

  return {
    mediaElementProps: {
      onPlay,
      onPause,
      onSeeked,
      onTimeUpdate,
    },
    mediaElementRef,
    localMediaPlaying,
    mediaTimestamp,
  };
};
