import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { PrivateTag } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { tagQueryKeys } from './tagsQueryKeys';

export interface ResourceTags {
  id: string;
  tags: PrivateTag[];
}
export const useQueryResourceTags = (
  resourceId?: string,
  queryOptions?: Partial<QueryObserverOptions<ResourceTags[]>>,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const query = useQuery({
    queryKey: tagQueryKeys.resources(resourceId ? [resourceId] : []),
    queryFn: async (params) => {
      const resourceId = (params.queryKey[1] as string[])[0];

      /**
       * drafts are created with id draft
       * we don't want to fetch the tags for the draft resource
       */
      if (resourceId === 'draft') {
        return [];
      }

      const { resources } = await client.v2('/v2/resource-tag-private/lookup', {
        method: 'post',
        body: {
          resourceIds: [resourceId],
        },
      });

      return resources;
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    ...queryOptions,
    enabled: isQueryEnabled([!!resourceId, isLoggedIn, queryOptions?.enabled]),
  });

  return {
    ...query,
    data: query.data?.find((item) => item.id === resourceId)?.tags || [],
  };
};

export const useQueryMultipleResourceTags = (
  resourceIds?: string[],
  queryOptions?: Partial<QueryObserverOptions<ResourceTags[]>>,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const query = useQuery({
    queryKey: tagQueryKeys.resources(resourceIds),
    queryFn: async (params) => {
      const resourceIds = params.queryKey[1] as string[];

      const { resources } = await client.v2('/v2/resource-tag-private/lookup', {
        method: 'post',
        body: {
          resourceIds,
        },
      });

      return resources;
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    ...queryOptions,
    enabled: isQueryEnabled([
      !!resourceIds && resourceIds.length > 0,
      isLoggedIn,
      queryOptions?.enabled,
    ]),
  });

  return query;
};
