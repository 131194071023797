import { ResourceDetail, ResourceDetailNotepad } from '@/src/modules/resources/resources.types';
import { isResourceDetailNotepad } from '@/src/modules/resources/utils/resourceDetailGuards';

export const mutateNotepadContentV2 = (
  resource: ResourceDetail,
  preview: ResourceDetailNotepad['data']['preview'],
): ResourceDetail => {
  if (isResourceDetailNotepad(resource)) {
    return {
      ...resource,
      data: {
        ...resource.data,
        preview: {
          ...resource.data.preview,
          ...preview,
          content: preview?.content || resource.data.preview?.content || '',
        },
      },
    };
  }

  return resource;
};
