import CommentComponent from '@/src/components/Comment/Comment';
import { ExpandedFdocSidebarAddComment } from '@/src/components/ExpandedFdoc/ExpandedFdocSidebar_AddComment';
import { CommentWithAuthor } from '@/src/modules/comments/comments.types';
import { useSidebarScrollbarFadeColor } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Sidebar';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { OptimisticDraft } from '@/src/types/draftable';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styles from './ExpandedFdoc.module.scss';

interface Props {
  isFullscreen: boolean;
  comments: OptimisticDraft<CommentWithAuthor>[];
  onStartEditingComment: (comment: OptimisticDraft<CommentWithAuthor>) => void;
  resourceId: string;
  editingComment?: OptimisticDraft<CommentWithAuthor> | null;
  onCancelEditingComment?: VoidFunction;
  animateIn?: boolean;
  canAutoFocus?: boolean;
}

const ExpandedFdocCommentsTab: React.FC<Props> = ({
  isFullscreen,
  comments,
  onStartEditingComment,
  editingComment,
  onCancelEditingComment,
  resourceId,
  animateIn,
  canAutoFocus = false,
}) => {
  const fadeColor = useSidebarScrollbarFadeColor();

  const commentsWithDates = useMemo(() => {
    let lastDay: string | null = null;
    const commentsWithDates = comments
      .slice(0)
      .sort((a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix())
      .reduce(
        (acc, comment) => {
          // format like: Thurs, Jul 25
          const day = dayjs(comment.createdAt).format('ddd, MMM D');

          if (day !== lastDay) {
            acc.push({ day, comments: [] });
            lastDay = day;
          }

          acc[acc.length - 1].comments.push(comment);

          return acc;
        },
        [] as { day: string; comments: OptimisticDraft<CommentWithAuthor>[] }[],
      );

    return commentsWithDates;
  }, [comments]);

  return (
    <>
      <ForcedTheme asChild colorScheme="dark" disabled={isFullscreen}>
        <ScrollArea
          scrollbarVariant="subtle"
          fade
          fadeColor={fadeColor}
          style={{
            flexGrow: 1,
          }}
        >
          <Conversation.List>
            <>
              {comments.length === 0 && (
                <span className={styles.item_preview__sidebar__comments__empty}>
                  No comments yet.
                </span>
              )}
              {commentsWithDates.map(({ day, comments }, i) => (
                <React.Fragment key={`${day}-${comments.length}-${i}`}>
                  <Conversation.InfoTag>{day}</Conversation.InfoTag>
                  {comments?.map((comment) => {
                    return (
                      <CommentComponent
                        key={comment.id}
                        comment={comment}
                        resourceId={resourceId}
                        onEdit={() => onStartEditingComment(comment)}
                        light={isFullscreen}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
            </>
          </Conversation.List>
        </ScrollArea>
      </ForcedTheme>

      {editingComment !== undefined && onCancelEditingComment && resourceId && (
        <ExpandedFdocSidebarAddComment
          isFullscreen={isFullscreen}
          editingComment={editingComment}
          onCancelEditingComment={onCancelEditingComment}
          resourceId={resourceId}
          animateIn={animateIn}
          canAutoFocus={canAutoFocus}
        />
      )}
    </>
  );
};

export default ExpandedFdocCommentsTab;
