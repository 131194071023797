import World from '@/public/images/icons/World.svg';
import { useResourceBookmarkDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import StateSpinner from '@/src/modules/resources/components/StateSpinner';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Image } from '@/src/modules/ui/components/Image';
import { P, TypographyContainer } from '@/src/modules/ui/components/Typography';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Void } from '../../ui/components/Void';
import { pageResourceEmbeds } from '../expandedResource.config';

const MetadataImageContainer = styled.div`
  border: 1px solid ${cssVar['color-border-primary']};
  background-color: ${cssVar['color-bg-primary']};
  border-radius: 14px;
  overflow: hidden;
  padding: 10px;
`;

const MetadataImageEmptyContainer = styled(Flex).attrs({
  direction: 'column',
  gap: 'elementsContainer',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
})`
  padding: 1rem;
  background-color: ${cssVar['color-bg-quarternary']};
  border-radius: 6px;
  max-height: 300px;
`;

const MetadataDescription = styled(Flex).attrs({
  direction: 'row',
  gap: 'elementsContainer',
  alignItems: 'center',
})`
  padding: 1rem;
  background-color: ${cssVar['color-bg-primary']};
  border-radius: 6px;
`;

const Container = styled(Flex).attrs({
  direction: 'column',
  gap: 'elementsContainer',
  flexGrow: 1,
})`
  width: 100%;
  max-width: 520px;
`;

export const PageResourceMetadata = forwardRef<HTMLDivElement>((_, ref) => {
  const { resource } = useResourceBookmarkDataSafe();
  const [imageFailed, setImageFailed] = React.useState(false);

  if (!resource.data.webpage) {
    return (
      <Container ref={ref}>
        <MetadataImageEmptyContainer>
          <StateSpinner size={22} thickness={3} />
          <P color="tertiary" size="sm">
            Fetching website image
          </P>
        </MetadataImageEmptyContainer>
        <MetadataDescription>
          <StateSpinner size={22} thickness={3} />
          <P color="tertiary" size="sm">
            Fetching website metadata
          </P>
        </MetadataDescription>
      </Container>
    );
  }

  const originUrl = resource.originUrl || resource.data.webpage?.url || '';
  const embed = pageResourceEmbeds.find((embed) =>
    embed.matchFn(resource.originUrl || resource.data.webpage?.url || ''),
  );

  const EmbedElement = embed?.renderElement ?? Void;

  const coverUrl = resource.thumbnail?.lg ?? resource.data.webpage?.image?.url;
  const title = resource.name;
  const description = resource.data.webpage?.description;

  const showImage = Boolean(!imageFailed && coverUrl);
  const showDescription = Boolean(title || description);

  return (
    <Container ref={ref}>
      <EmbedElement url={originUrl}>
        {showImage && (
          <MetadataImageContainer>
            <Image
              src={coverUrl}
              alt={title || 'Webpage preview'}
              onError={() => setImageFailed(true)}
              fullWidth
            />
          </MetadataImageContainer>
        )}

        {!showImage && !showDescription && (
          <Flex
            direction="column"
            gap="elementsContainer"
            alignItems="center"
            justifyContent="center"
            flexGrow
          >
            <P color="tertiary">
              <World
                style={{
                  width: 64,
                  height: 64,
                }}
              />
            </P>
            <P color="tertiary" size="sm">
              No link preview
            </P>
          </Flex>
        )}
      </EmbedElement>
      {showDescription && (
        <MetadataDescription>
          <TypographyContainer>
            {title && (
              <P color="tertiary" size="sm" weight={500}>
                {title}
              </P>
            )}
            {description && (
              <P color="tertiary" size="sm" wordBreak="break-word">
                {description}
              </P>
            )}
          </TypographyContainer>
        </MetadataDescription>
      )}
    </Container>
  );
});

PageResourceMetadata.displayName = 'PageResourceMetadata';
