import { useResourceHighlightDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { noteTextFormat } from '@/src/utils/text';
import styled from 'styled-components';

const TextWrapper = styled.div`
  padding: 1.5rem;
`;
const Text = styled.span`
  line-height: 1.6;
  color: ${cssVar['color-text-primary']};
  background: rgba(186, 206, 255, 0.3);
  box-shadow:
    0px 2.25px 0px 0px rgba(186, 206, 255, 0.3),
    0px -2.25px 0px 0px rgba(186, 206, 255, 0.3);

  ${mediaNotMobile} {
    font-size: 1.0625rem;
    line-height: 1.7;
  }
`;

export const ResourceHighlightText = () => {
  const { resource } = useResourceHighlightDataSafe();
  return (
    <TextWrapper>
      {resource.data.extensionContext?.text && (
        <Text>{noteTextFormat(resource.data.extensionContext?.text)}</Text>
      )}
    </TextWrapper>
  );
};
