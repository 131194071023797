import { OfViewMode } from '@/src/components/ViewModeSwitcher/ViewModeSwitcher';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useResourceClickHandler } from '@/src/modules/resources/components/ResourcePreview/hooks/useResourceClickHandler';
import { ResourcePreview } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview';
import { ResourcesPreviewUiConfigProvider } from '@/src/modules/resources/components/ResourcePreview/resourcePreviewUiConfigContext';
import { ResourceDetail, ResourceDetailFolder } from '@/src/modules/resources/resources.types';
import { ResourceUiStoreContext } from '@/src/modules/resources/stores/useResourcesUiStore';
import { IntersectionObserverAnchor } from '@/src/modules/ui/components/IntersectionObserver/IntersectionObserver';
import { OptimisticDraft } from '@/src/types/draftable';
import { LayoutGroup } from 'framer-motion';
import React from 'react';

/**
 * This is a barebone list of resources without additional features
 * Wrap the list with a container which controls width of items.
 *
 * This is not meant to replace the FdocList but rather meant to serve as a base layer of smarter lists.
 */
export const ResourceList: React.FC<{
  viewMode: OfViewMode;
  disableItemPreviewContextMenu?: boolean;

  colorLabels?: ApiColorLabel[];
  resources: OptimisticDraft<ResourceDetail>[];
  fetchNextPage?: () => void;

  uiContext?: ResourceUiStoreContext;
  layoutId: string;

  onClickFolder?: (resourceFolder: ResourceDetailFolder) => void;
  onClickResource?: (resource: ResourceDetail) => void;
}> = ({
  viewMode,
  colorLabels,
  // resources
  resources,
  fetchNextPage,

  uiContext,
  layoutId,
  onClickResource,
  onClickFolder,
}) => {
  const handleClickResource = useResourceClickHandler({
    onClickResource,
    onClickFolder,
  });

  return (
    <>
      <ResourcesPreviewUiConfigProvider uiContext={uiContext}>
        <LayoutGroup id={layoutId}>
          {resources.map((resource) => (
            <ResourcePreview
              key={resource.id}
              variant={viewMode === 'List' ? 'list' : 'default'}
              onClickResource={handleClickResource}
              resource={resource}
              colorLabels={colorLabels}
            />
          ))}
        </LayoutGroup>
      </ResourcesPreviewUiConfigProvider>

      <IntersectionObserverAnchor
        onIntersect={fetchNextPage}
        enabled={!!fetchNextPage}
        rootMargin="1000px"
      />
    </>
  );
};
