import tiptapExtensions from '@/src/components/Tiptap/Extensions';
import { generateJSON, getSchema } from '@tiptap/react';
import { prosemirrorJSONToYDoc } from 'y-prosemirror';
import { Doc } from 'yjs';

export const getYdocFromHtmlContent = (htmlContent: string): Doc => {
  const json = generateJSON(htmlContent, tiptapExtensions);
  const schema = getSchema(tiptapExtensions);
  const yDoc = prosemirrorJSONToYDoc(schema, json, 'default');

  return yDoc;
};
