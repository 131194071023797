/**
 * to retrieve video id from youtube url
 * new RegExp(YOUTUBE_URL_REGEX).exec(url)[1]
 */
export const YOUTUBE_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|shorts|watch)(?:\?(.*&)?v=|\S*\/))|youtu\.be\/)([a-zA-Z0-9_-]{11})/gi;

export const VIMEO_URL_REGEX = /(?:http:\/\/)?(?:www\.)?(?:vimeo\.com)\/(.+)/g;

// detect twitter URLs, twitter is now also x.com so we need to account for that
export const TWITTER_URL_REGEX =
  /(?:https?:\/\/)?(?:(?:twitter\.com)|(?:x\.com))\/(.*)(\/status)\/(.+)/g;

// detect spotify track, album, or playlist URLs
export const SPOTIFY_URL_REGEX =
  /^(?:https?:\/\/)?(?:play|open)\.spotify\.com\/(track|album|playlist|artist|show|episode)\/(.+)|https?:\/\/embed.spotify.com\/\?uri=spotify:(track|album|playlist|artist|show|episode):(.+)$/;

export const parseUrlSafe = (url: string): URL | null => {
  try {
    return new URL(url);
  } catch (err: unknown) {
    return null;
  }
};

export function convertUrlToSpotifyEmbedUrl(url: string) {
  const match = url.match(SPOTIFY_URL_REGEX);

  if (!match) {
    return null; // URL is not a recognized Spotify link
  }

  if (url.includes('embed.spotify.com')) {
    return url; // If it's already an embed URL, return it as is
  }

  const resourceType = match[1] || match[6];
  const resourceId = match[2] || match[7];

  return `https://open.spotify.com/embed/${resourceType}/${resourceId}`;
}
