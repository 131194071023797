import useAuthStore, { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { PermissionWithUser } from '@/src/modules/permission/permission.types';
import { useWoody } from '@/src/services/woody/woody';
import { isWoodyError } from '@/src/utils/error';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { membersQueryKeys } from './membersQueryKeys';

const defaultData: PermissionWithUser[] = [];

export const useQueryMembersByListId = (
  resourceId?: string,
  queryOptions?: Partial<QueryObserverOptions<PermissionWithUser[]>>,
) => {
  const user = useAuthStore((state) => state.user, shallow);
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const enabled = isQueryEnabled([!!resourceId, isLoggedIn, queryOptions?.enabled]);

  const query = useQuery({
    queryKey: membersQueryKeys.byResourceId(resourceId),
    queryFn: async (params) => {
      /**
       * query is not enabled if the listId is not provided
       * using it here should be considered safe
       */
      const resourceIdSafe = params.queryKey[1] as string;
      const res = await client.v2({
        endpoint: '/v2/resources/{resourceId}/permissions',
        params: {
          resourceId: resourceIdSafe,
        },
      });

      return res;
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    // small stale time so it doesn't refetch everytime simple space is rendered when filtering
    staleTime: 1000 * 15,
    retry(failureCount, error) {
      if (isWoodyError(error) && error.status === 404) return false;
      return failureCount < 3;
    },
    ...queryOptions,
    enabled,
  });

  const permissions = query.data || defaultData;
  const membersWithRole = React.useMemo(
    () => permissions.map((permission) => ({ ...permission.user, role: permission.role })),
    [permissions],
  );

  const isCurrentUserMember = React.useMemo(
    () => !!membersWithRole?.some((member) => member.id === user?.id),
    [membersWithRole, user],
  );

  return {
    ...query,
    membersWithRole,
    /**
     * @deprecated
     * ResourceDetail.role will be a new property to check against if the user is member or not
     */
    isCurrentUserMember,
  };
};
