import { useDefaultedEvent } from '@/src/lib/events';
import { MarkdownRender } from '@/src/modules/markdown/components/MarkdownRender';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { P } from '@/src/modules/ui/components/Typography';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion } from 'framer-motion';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import styled, { css } from 'styled-components';

const ConversationItem = styled(Flex).attrs((p) => {
  return {
    gap: 'element',
    direction: 'column',
    ...p,
  };
})`
  width: 100%;
`;

const ConversationActionWrap = styled(Flex).attrs((p) => {
  return {
    gap: 'text',
    ...p,
  };
})`
  width: 100%;

  ${ConversationItem}:nth-child(2) {
    padding: 0.625rem;
    background: ${cssVar['color-bg-senary']};
    border-radius: ${cssVar['radius-input']};
  }
`;

const ConversationActionIcons = styled(Flex).attrs((p) => {
  return {
    gap: 'text',
    direction: 'column',
    ...p,
  };
})`
  padding: 12px 0;
`;

const ConversationItemHeader = styled(Flex).attrs((p) => {
  return {
    gap: 'element',
    alignItems: 'center',
    ...p,
  };
})`
  user-select: none;
`;

const ConversationItemHeaderName = styled(P).attrs((p) => {
  return {
    weight: 600,
    size: 'sm-plus',
    ...p,
  };
})`
  flex-grow: 1;
`;

const ConversationItemBody = styled(MarkdownRender)`
  font-size: 0.8125rem;
  line-height: 1.15rem;
  color: ${cssVar['color-text-primary']};
  word-break: break-word;
  hyphens: auto;
  user-select: text;
  * {
    user-select: auto;
  }

  --margin-block-start: 0em;
  --margin-block-end: 0em;
  --padding-inline-start: 1em;
  --heading-scale: 1.2;
`;

const ConversationList = styled(Flex).attrs((p) => {
  return {
    gap: 'sectionsShort',
    direction: 'column',
    alignItems: 'center',
    flexGrow: 1,
    ...p,
  };
})`
  padding: 1.25rem 1rem;
`;

const ConversationInfoTag = styled.div`
  align-self: center;
  vertical-align: middle;
  text-align: center;

  min-width: 2.5rem;
  height: 1.3125rem;
  background-color: ${cssVar['color-bg-contrast']};
  outline: 1px solid ${cssVar['color-border-primary']};
  color: ${cssVar['color-text-tertiary']};
  border-radius: 0.5rem;
  padding: 0 0.375rem;
  font-size: 0.8125rem;
  font-weight: 500;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const ConversationForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  flex-shrink: 0;
  padding: 1rem;
  border-top: 1px solid rgba(${cssVar['color-bg-contrast-rgb']}, 0.1);

  position: relative;
`;

const ConversationFormField = styled(Flex).attrs((p) => {
  return {
    gap: 'element',
    alignItems: 'center',
    as: 'label',
    ...p,
  };
})`
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: ${cssVar['color-bg-secondary']};
  overflow: hidden;
  border-radius: 0.625rem;
  border: 1px solid transparent;

  &:focus-within {
    ${renderCssBasedOnColorScheme({
      light: css`
        border: 1px solid #30f;
        box-shadow: 0px 4px 24px 0px rgba(51, 0, 255, 0.2);
      `,
      dark: css`
        border: 1px solid white;
        box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.2);
      `,
    })}
  }
`;

const StyledConversationFormTextAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  max-height: 68px;
  min-height: 17px;
  flex-grow: 1;
`;

const StyledConversationFormTextArea = styled.textarea`
  flex-grow: 1;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 500;
  color: ${cssVar['color-text-primary']};
  background: none;
  border: none;
  outline: none;

  resize: none;

  display: flex;
  overflow: visible;

  word-break: break-word;
  white-space: pre-wrap;

  &::placeholder {
    color: ${cssVar['color-text-placeholder']};
  }
`;

const ConversationFormTextArea = forwardRef<
  React.ElementRef<typeof StyledConversationFormTextArea>,
  React.ComponentPropsWithoutRef<typeof StyledConversationFormTextArea>
>((props, propRef) => {
  const [innerRef, setInnerRef] = React.useState<HTMLTextAreaElement | null>(null);
  const [content, setContent] = React.useState<string>('');
  const [height, setHeight] = useState(17);

  const ref = useMemo(() => mergeRefs([propRef, setInnerRef]), [propRef]);

  /**
   * automatically resize textarea height based on content
   */
  useEffect(() => {
    if (!innerRef) return;

    // update height of textarea
    innerRef.style.height = 'auto';
    innerRef.style.height = innerRef.scrollHeight + 'px';

    // scroll to bottom
    innerRef.scrollTop = innerRef.scrollHeight;

    const rect = innerRef.getBoundingClientRect();
    setHeight(Math.min(68, Math.max(17, rect.height)));
  }, [innerRef, content, props.value]);

  return (
    <StyledConversationFormTextAreaWrapper
      style={{
        height,
      }}
    >
      <ScrollArea
        style={{
          height,
          width: '100%',
        }}
      >
        <StyledConversationFormTextArea
          ref={ref}
          rows={1}
          {...props}
          onChange={useDefaultedEvent((e) => setContent(e.target.value), props.onChange)}
        />
      </ScrollArea>
    </StyledConversationFormTextAreaWrapper>
  );
});

const ConversationFormButton = styled(Button).attrs((p) => {
  return {
    variant: 'primary',
    shape: 'round',
    size: 'xss',
    ...p,
  };
})``;

ConversationFormTextArea.displayName = 'ConversationFormTextArea';

const ConversationQuickActions = styled(Flex).attrs((p) => {
  return {
    gap: 'elementsContainer',
    alignItems: 'center',
    ...p,
  };
})`
  padding: 1rem;
  flex-shrink: 0;
`;

const ConversationQuickAction = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 12px;
  border-radius: ${cssVar['radius-input-lg']};
  background: ${cssVar['color-bg-senary']};
  color: ${cssVar['color-text-primary']};

  ${mediaHover} {
    &:hover:enabled {
      box-shadow: 0 0 10rem 0 rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.1) inset;
    }
  }
`;

const ActionWrap = ConversationActionWrap;
const ActionIcons = ConversationActionIcons;
const Item = ConversationItem;
const ItemHeader = ConversationItemHeader;
const ItemHeaderName = ConversationItemHeaderName;
const ItemBody = ConversationItemBody;
const List = ConversationList;
const InfoTag = ConversationInfoTag;
const Form = ConversationForm;
const FormField = ConversationFormField;
const FormTextArea = ConversationFormTextArea;
const FormButton = ConversationFormButton;
const QuickActions = ConversationQuickActions;
const QuickAction = ConversationQuickAction;

export {
  ActionIcons,
  ActionWrap,
  ConversationActionIcons,
  ConversationActionWrap,
  ConversationForm,
  ConversationFormField,
  ConversationFormTextArea,
  ConversationInfoTag,
  ConversationItem,
  ConversationItemBody,
  ConversationItemHeader,
  ConversationItemHeaderName,
  ConversationList,
  Form,
  FormButton,
  FormField,
  FormTextArea,
  InfoTag,
  Item,
  ItemBody,
  ItemHeader,
  ItemHeaderName,
  List,
  QuickAction,
  QuickActions,
};
