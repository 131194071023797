import Skeleton from '@/src/components/Skeleton/Skeleton';
import { NotepadPreviewWithBlocks } from '@/src/components/Tiptap/types';
import { getYdocFromHtmlContent } from '@/src/components/Tiptap/utils/getYDocFromHtmlContent';
import { useOnWindowClose } from '@/src/hooks/useOnWindowClose';
import { useSingletonRef } from '@/src/hooks/useSingletonRef';
import { useUnmount } from '@/src/hooks/useUnmount';
import { isObject } from '@/src/lib/utils';
import { useQueryMultiplayerSession } from '@/src/modules/multiplayer/queries/useQueryMultiplayerSession';
import { useResourceNotepadDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { useQueryNotepadResourceState } from '@/src/modules/resource-detail/queries/useQueryNotepadResourceState';
import { useQueryNotepadResourceSync } from '@/src/modules/resource-detail/queries/useQueryNotepadResourceSync';
import {
  HybridSyncProvider,
  HybridSyncProviderMode,
} from '@/src/modules/resource-detail/utils/HybridSyncProvider';
import { getUserColorFromId } from '@/src/utils/color';
import { isWoodyError } from '@/src/utils/error';
import { MultiplayerSessionType } from '@fabric/woody-client';
import { Transaction } from '@tiptap/pm/state';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import styles from './MultiplayerEditor.module.scss';
import Tiptap, { TiptapProps } from './Tiptap';

const WEBSOCKET_MAX_RETRIES = 3;

/**
 * Legacy state, **should** be removed when we refactor the `ExpandedFdoc` component away.
 */
export type MultiplayerState =
  | 'LOADING'
  | 'OFFLINE'
  | 'CONNECTING'
  | 'CONNECTED'
  | 'SYNCED'
  | 'FAILURE';

const TextSkeleton = styled(Skeleton).attrs((props) => {
  return {
    ...props,
    borderRadius: 6,
    height: 18,
  };
})``;

const MultiplayerEditor: React.FC<{
  tiptapProps?: TiptapProps;
  onOfflineChange?: (updates: Uint8Array, inMultiplayer?: boolean) => void;
  onHasPendingChanges?: (hasPendingChanges: boolean) => void;
  onModeChange?: (mode: HybridSyncProviderMode) => void;
  onStateSynced?: () => void;

  /**
   * @deprecated Legacy state, **should** be removed when we refactor the `ExpandedFdoc` component away.
   */
  onMultiplayerStateChange?: (state: MultiplayerState) => void;
}> = ({
  onOfflineChange,
  tiptapProps = {},
  onModeChange: onHybridSyncProviderModeChange,
  onMultiplayerStateChange,
  onHasPendingChanges,
  onStateSynced,
}) => {
  const { resource: notepad } = useResourceNotepadDataContextSafe();

  /**
   * When a notepad is shared and not a draft, it is an online notepad, as such
   * we connect to an YJS server in Jamsocket for realtime collaboration.
   */
  const isOnlineNotepad = notepad.isShared && !notepad.isDraft;

  /**
   * This helps with draft ⇄ notepad transitions.
   */
  const notepadKey = notepad.draftId ?? notepad.id;

  /**
   * This exists simply to prevent seeing an empty editor while iniitializing.
   */
  const [isLoading, setIsLoading] = useState(!notepad.isDraft);

  const {
    data: notepadState,
    isLoading: notepadStateLoading,
    error: notepadStateError,
  } = useQueryNotepadResourceState(notepad, {
    enabled: !notepad?.isDraft,
  });

  const {
    data: syncUpdate,
    isLoading: syncUpdateLoading,
    isFetching: syncUpdateFetching,
  } = useQueryNotepadResourceSync(notepad, notepadState, {
    enabled: !notepad?.isDraft,
    refetchInterval: isOnlineNotepad ? false : 9000,
    refetchOnMount: 'always',
  });

  const {
    data: session,
    isLoading: isLoadingSession,
    refetch: refetchSession,
  } = useQueryMultiplayerSession(
    isOnlineNotepad
      ? {
          resourceId: notepad.id,
          type: MultiplayerSessionType.YJS,
        }
      : undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  const yDocRef = useSingletonRef<Y.Doc>(() => new Y.Doc());
  const websocketProviderRef = useSingletonRef<WebsocketProvider>(
    () =>
      new WebsocketProvider('', notepad.id, yDocRef.current, {
        connect: false,
        // Max amount of time to retry connection on failure. It's an exponential backoff.
        maxBackoffTime: 4000,
      }),
  );
  const hybridSyncProviderRef = useSingletonRef<HybridSyncProvider>(
    () =>
      new HybridSyncProvider({
        doc: yDocRef.current,
        debounceWait: 5000,
        initialMode: isOnlineNotepad
          ? HybridSyncProviderMode.SemiOffline
          : HybridSyncProviderMode.FullyOffline,
      }),
  );

  const websocketConnectionRetries = useRef(0);

  /**
   * If we are in an online notepad, we connect to it.
   * While connecting the hybrid sync provider will handle updating the backend,
   * this is just in case the user quits the editor while connecting, preventing
   * data loss.
   */
  useEffect(() => {
    if (!isOnlineNotepad || isLoadingSession || !session) return;

    const webSocketProvider = websocketProviderRef.current;
    const hybridSyncProvider = hybridSyncProviderRef.current;

    const encodedParams = new URLSearchParams({
      token: session.auth.token,
    });

    webSocketProvider.url = session.url + '/' + notepad.id + '?' + encodedParams.toString();
    webSocketProvider.shouldConnect = true;

    const onStatus = ({ status }: { status: 'connected' | 'connecting' | 'disconnected' }) => {
      if (status === 'connected') {
        websocketConnectionRetries.current = 0;
        return;
      }

      hybridSyncProvider.setMode(HybridSyncProviderMode.SemiOffline);
    };

    const onSynced = () => {
      hybridSyncProvider.setMode(HybridSyncProviderMode.Online);
      setIsLoading(false);
    };

    const onConnError = () => {
      websocketConnectionRetries.current += 1;
      if (websocketConnectionRetries.current > WEBSOCKET_MAX_RETRIES) {
        websocketConnectionRetries.current = 0;
        hybridSyncProvider.setMode(HybridSyncProviderMode.FullyOffline);
        refetchSession();
      } else {
        webSocketProvider.connect();
      }
    };

    webSocketProvider.on('status', onStatus);
    webSocketProvider.on('synced', onSynced);
    webSocketProvider.on('sync', onSynced);
    webSocketProvider.on('connection-error', onConnError);
    webSocketProvider.on('connection-close', onConnError);

    webSocketProvider.connect();

    return () => {
      webSocketProvider.off('status', onStatus);
      webSocketProvider.off('synced', onSynced);
      webSocketProvider.off('sync', onSynced);
      webSocketProvider.off('connection-error', onConnError);
      webSocketProvider.off('connection-close', onConnError);
      webSocketProvider.disconnect();
    };
  }, [
    hybridSyncProviderRef,
    websocketProviderRef,
    isLoadingSession,
    isOnlineNotepad,
    notepad.id,
    session,
    refetchSession,
  ]);

  /**
   * If not online (or draft) we setup a listener for the hybrid sync provider,
   * it will send them debounced (depending on the property above) upstream, where
   * it can be listened and used to mutate the state on the backend.
   */
  useEffect(() => {
    if (notepad.isDraft) return;

    const hybridSyncProvider = hybridSyncProviderRef.current;

    hybridSyncProvider.setMode(HybridSyncProviderMode.FullyOffline);

    const onUpdate = (update: Uint8Array) => {
      onOfflineChange?.(update, isOnlineNotepad);
    };

    hybridSyncProvider.on('update', onUpdate);

    return () => {
      hybridSyncProvider.off('update', onUpdate);
    };
  }, [isOnlineNotepad, onOfflineChange, notepad.isDraft, hybridSyncProviderRef]);

  /**
   * If we have a notepad state we initialize the hybrid sync provider with it.
   */
  useEffect(() => {
    if (!notepadState || notepadStateLoading) return;

    const hybridSyncProvider = hybridSyncProviderRef.current;

    if (!hybridSyncProvider.synced) hybridSyncProvider.initialize(notepadState);
  }, [hybridSyncProviderRef, notepadState, notepadStateLoading]);

  /**
   * If we don't have a notepad state, and only on a 404 error, we will convert any
   * legacy notepad data to a YJS state and initialize the hybrid sync provider with it
   * as well as sending the offline change upstream.
   * This is not run when in online mode because the YJS server handles the conversion.
   */
  useEffect(() => {
    const hybridSyncProvider = hybridSyncProviderRef.current;

    if (
      notepadState ||
      notepadStateLoading ||
      isOnlineNotepad ||
      notepad.isDraft ||
      !isWoodyError(notepadStateError) ||
      notepadStateError.status !== 404 ||
      hybridSyncProvider.synced
    )
      return;

    const content = notepad.data.preview?.content;
    if (typeof content !== 'string') return;

    const yDoc = getYdocFromHtmlContent(content);
    const update = Y.encodeStateAsUpdate(yDoc);

    hybridSyncProvider.initialize(update);
    onOfflineChange?.(update);
  }, [
    hybridSyncProviderRef,
    isOnlineNotepad,
    notepad.isDraft,
    notepadState,
    notepadStateError,
    notepadStateLoading,
    onOfflineChange,
    notepad.data.preview?.content,
  ]);

  /**
   * If offline and we got a sync update we apply it to the hybrid sync provider.
   */
  useEffect(() => {
    if (!syncUpdate || syncUpdateLoading || syncUpdateFetching) return;

    const hybridSyncProvider = hybridSyncProviderRef.current;

    hybridSyncProvider.applyUpdate(syncUpdate);
  }, [hybridSyncProviderRef, syncUpdate, syncUpdateFetching, syncUpdateLoading]);

  /**
   * When unmounting we make sure to destroy the providers and send any penidng updates upstream.
   */
  useUnmount(() => {
    const websocketProvider = websocketProviderRef.current;

    websocketProvider.destroy();
    const pendingUpdate = hybridSyncProviderRef.current.destroy();
    if (pendingUpdate) {
      onOfflineChange?.(pendingUpdate);
    }
  }, true);

  /**
   * Legacy handling for mulitplayer state change.
   */
  useEffect(() => {
    const hybridSyncProvider = hybridSyncProviderRef.current;

    const onModeChange = (mode: HybridSyncProviderMode) => {
      onHybridSyncProviderModeChange?.(mode);

      switch (mode) {
        case HybridSyncProviderMode.FullyOffline:
          onMultiplayerStateChange?.('OFFLINE');
          break;
        case HybridSyncProviderMode.SemiOffline:
          onMultiplayerStateChange?.('CONNECTING');
          break;
        case HybridSyncProviderMode.Online:
          onMultiplayerStateChange?.('SYNCED');
          break;
      }
    };

    const onSynced = () => {
      onMultiplayerStateChange?.('OFFLINE');
      onStateSynced?.();
      setIsLoading(false);
    };

    const onPendingUpdatesChange = (pendingUpdates: Uint8Array[]) => {
      onHasPendingChanges?.(pendingUpdates.length > 0);
    };

    hybridSyncProvider.on('modeChange', onModeChange);
    hybridSyncProvider.on('synced', onSynced);
    hybridSyncProvider.on('pendingUpdatesChange', onPendingUpdatesChange);

    return () => {
      hybridSyncProvider.off('modeChange', onModeChange);
      hybridSyncProvider.off('synced', onSynced);
      hybridSyncProvider.off('pendingUpdatesChange', onPendingUpdatesChange);
    };
  }, [
    hybridSyncProviderRef,
    onHasPendingChanges,
    onMultiplayerStateChange,
    onHybridSyncProviderModeChange,
    onStateSynced,
  ]);

  /**
   * If closing the window we make sure to send any peinding updates upstream so
   * they are not lost.
   */
  useOnWindowClose(true, () => {
    const hybridSyncProvider = hybridSyncProviderRef.current;

    // Instead of destroying we consume the updates,
    // just in case the user cancels the page close we don't destroy the provider.
    const pendingUpdate = hybridSyncProvider.consumePendingUpdates();
    if (pendingUpdate) {
      onOfflineChange?.(pendingUpdate);
    }
  });

  /**
   * This includes extra checks required to prevent the UI creating empty notepads
   * because of non visible changes to the document. (default metadata)
   */
  const onTiptapChange = (
    content: NotepadPreviewWithBlocks,
    binary: Uint8Array,
    transaction?: Transaction,
  ) => {
    /**
     * The y-sync$ meta is added by the sync plugin and causes a lot of transactions to occur, mainly related
     * to it initializing the document. We don't want to send these transactions to the server because they are just
     * noise and don't represent any real changes to the document.
     * But the sync extension doesn't support the default history plugin, so it implements its own undo/redo system.
     * Which is very important to be sent to the server, otherwise, the undo/redo history will be lost which is a bad UX.
     */
    const ySyncMeta = transaction?.getMeta('y-sync$');
    /**
     * This checks if the transaction is an undo/redo operation, if it is, we want to send it to the server.
     */
    const isUndoRedoOperation = Boolean(isObject(ySyncMeta) && ySyncMeta.isUndoRedoOperation);

    if (
      // don't send transactions that are just noise
      (ySyncMeta && !isUndoRedoOperation) ||
      /**
       * if it's a draft we don't need to send $nodeInfo transactions, this is from our custom
       * Node Info extension which applies unique IDs to nodes that don't have them. While it's a draft
       * this would cause a notepad to be created before the user interacts with the editor because it is simply
       * setting up the ID for the first elements.
       */
      ((notepad?.isDraft || !notepad?.id) && transaction?.getMeta('$nodeInfo'))
    )
      return;

    tiptapProps.onChange?.(content, binary, transaction);
  };

  /**
   * We can show the Tiptap editor if we either have fdoc.id and the document and provider are ready
   * or if we don't have fdoc.id and the document is ready and awareness is ready
   */
  return (
    <AnimatePresence>
      {!isLoading ? (
        <Tiptap
          {...tiptapProps}
          onChange={onTiptapChange}
          onlineProvider={websocketProviderRef.current}
          yDoc={yDocRef.current}
          key={`tiptap-${notepadKey}`}
          userAwareness={
            session
              ? {
                  name: session.auth.user.name,
                  color: session.auth.user.id
                    ? getUserColorFromId(session.auth.user.id)
                    : '#000000',
                }
              : null
          }
          editable={!session?.auth.user.readOnly && tiptapProps?.editable !== false}
          // Passing in the value when it's not a draft can cause data duplication or loss, so be careful when changing this
          // Reason being that the notepad value will be loaded via the YJS document, and tiptap will try to override/merge it wrongly
          // And for draft it's needed for e.g. pasting content into the app, so it will populate the editor with the draft content
          initialValue={notepad.isDraft ? tiptapProps.initialValue : undefined}
        />
      ) : (
        <motion.div
          className={styles.loader}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          // we have a small delay on the exit to let Tiptap sync up with YJS, it is not perfect but it works
          exit={{ opacity: 0, transition: { delay: 0.2, duration: 0.1 } }}
        >
          <TextSkeleton width={'93%'} />
          <TextSkeleton width={'91%'} />
          <TextSkeleton width={'87%'} />
          <TextSkeleton width={'98%'} />
          <TextSkeleton width={'64%'} />
          <span />
          <TextSkeleton width={'89%'} />
          <TextSkeleton width={'94%'} />
          <TextSkeleton width={'92%'} />
          <TextSkeleton width={'84%'} />
          <TextSkeleton width={'53%'} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MultiplayerEditor;
